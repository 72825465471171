import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { datasetsServices } from "../../../../APIServices/AIAlpha/datasetsServices";

const UploadDatasetsModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  studyId,
  getDataSetsByProgramStudyId,
}) => {
  const [uploadFile, setUploadFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUploadFile(file);
  };

  const uploadDatasetsByStudyId = async () => {
    const formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("studyId", studyId);
    const data = await datasetsServices.uploadDatasetsByStudyId(formData);
    if (data?.statusCode === 200) {
      closeModal();
      getDataSetsByProgramStudyId(studyId);
    }
  };

  const closeModal = () => {
    Hide();
  };

  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Upload Datasets<span>*</span>
            </Form.Label>
            <Form.Control
              onChange={handleFileChange}
              type="file"
              accept=".csv, .xls, .xlsx"
              required
            />
          </Form.Group>
          <Form.Text>
            <b>
              Download Template: For CSV{" "}
              <a
                href="../FileTemplate/CSVDatasetsTemplate.csv"
                style={{ color: "var(--accent-200)" }}
                download
              >
                <b>Click Here</b>
              </a>
              , For Excel{" "}
              <a
                href="../FileTemplate/EXCELDatasetsTemplate.xlsx"
                style={{ color: "var(--accent-200)" }}
                download
              >
                <b>Click Here</b>
              </a>
            </b>
          </Form.Text>
        </Row>
      </Modal.Body>
      <Modal.Footer className="py-1 px-3">
        <div className="text-end footer_button">
          <button
            className="themeButton"
            disabled={!uploadFile}
            onClick={uploadDatasetsByStudyId}
          >
            {Submit}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadDatasetsModal;
