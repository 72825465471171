import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ProfilePic from "../image/blackprof.webp";
import { authServices } from "../APIServices/authServices";
import SecurityModal from "./Account/Security/Security";
import PasswordModal from "./Account/Password/PasswordModal";
import UseToggle from "../Hooks/useToggle";
import { creditsAIServices } from "../APIServices/AIAlpha/creditsAIServices";

const MainHeader = ({ userCurrrentData }) => {
  const ProfileRef = useRef(null);
  const [profileActive, setProfileActive] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const toggleProfile = () => {
    setProfileActive(!profileActive);
  };

  useEffect(() => {
    const handleClickProfileMenuOutside = (event) => {
      if (ProfileRef.current && !ProfileRef.current.contains(event.target)) {
        setProfileActive(false);
      }
    };
    document.addEventListener("click", handleClickProfileMenuOutside, true);

    return () => {
      document.removeEventListener(
        "click",
        handleClickProfileMenuOutside,
        true
      );
    };
  }, []);

  const logout = async () => {
    await authServices.logout();
    handleBredcrumbChange();
  };

  const [authType, setAuthType] = useState("");
  const [authTypeData, setAuthTypeData] = useState("");
  const [status, setStatus] = useState(false);
  const [appTwoFA, setAppTwoFA] = useState(false);
  const [emailTwoFA, setEmailTwoFA] = useState(false);

  const getTwoFactorAuthType = async () => {
    let data = await authServices.getTwoFactorAuthType();
    setAuthType(data?.authType);
    setAuthTypeData(data?.authType);
    setStatus(data?.status);
    setAppTwoFA(data?.appTwoFA);
    setEmailTwoFA(data?.emailTwoFA);
  };

  const openTwoFactorAuth = () => {
    getTwoFactorAuthType();
    setToggle1();
  };

  const getTotalRemainingCredit = async () => {
    const data = await creditsAIServices.getTotalRemainingCredit();
    setRemainingCredits(data?.remainingCredits);
  };

  useEffect(() => {
    getTwoFactorAuthType();
    getTotalRemainingCredit();
    setFirstName(
      userCurrrentData?.firstName ? userCurrrentData?.firstName : ""
    );
    setMiddleName(
      userCurrrentData?.middleName ? userCurrrentData?.middleName : ""
    );
    setLastName(userCurrrentData?.lastName ? userCurrrentData?.lastName : "");
    setProfileImage(userCurrrentData?.profileImage ? userCurrrentData?.profileImage : ProfilePic)
  }, [userCurrrentData]);

  const handleBredcrumbChange = () => {
    localStorage.removeItem("activeDocumentBreadcrumbData");
    localStorage.removeItem("breadcrumbDocumentData");
    localStorage.removeItem("activeProgramBreadcrumbData");
    localStorage.removeItem("breadcrumbProgramData");
    localStorage.removeItem("libriryType");
  };

  return (
    <>
      <div className="header_section">
        <Container fluid>
          <Row>
            <Col>
              <div className="hstack justify-content-between">
                <NavLink to="/home" onClick={handleBredcrumbChange}>
                  <p className="m-0" style={{ fontSize: 18 }}>
                    <b>Galax® Alpha</b>
                  </p>
                </NavLink>

                <div className="hstack gap-2">
                  <NavLink to={"/account/recharge"}>
                    <p className="m-0" title="Remaining Credits">
                      Remaining Credits : <b>{remainingCredits} <i className="fa-solid fa-coins ms-1"></i></b>
                    </p>
                  </NavLink>
                  <div
                    ref={ProfileRef}
                    className={`profile position-relative ${
                      profileActive ? "active" : ""
                    }`}
                  >
                    <div
                      className="icon_wrap hstack gap-1"
                      onClick={toggleProfile}
                    >
                      <img
                        src={profileImage}
                        alt="Profile"
                        title="User Profile"
                      />
                      <div
                        className={`title_name hstack gap-1 px-2 p-1 ${
                          profileActive ? "profileNameActive" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <p className="m-0">
                          <b>{firstName + " " + middleName + " " + lastName}</b>
                        </p>
                        <i className="fas fa-chevron-down"></i>
                      </div>
                    </div>

                    <div className="profile_dd shadow">
                      <ul className="profile_ul">
                        <li>
                          <NavLink
                            to="/account/analytics"
                            onClick={handleBredcrumbChange}
                          >
                            <i className="fa-solid fa-user me-2"></i> Account
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={() => {
                              toggleProfile();
                              openTwoFactorAuth();
                            }}
                          >
                            <i className="fa-solid fa-shield me-2"></i>
                            2FA
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={() => {
                              toggleProfile();
                              setToggle();
                            }}
                          >
                            <i className="fa-solid fa-lock me-2"></i>
                            Change Password
                          </NavLink>
                        </li>
                        <li>
                          <NavLink onClick={logout} className="logout-link">
                            <i className="fas fa-sign-out-alt me-2"></i>
                            Logout
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <PasswordModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Update Password"}
        Submit={"Update"}
      />
      <SecurityModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={"Two Factor Authentication"}
        getTwoFactorAuthType={getTwoFactorAuthType}
        setAuthType={setAuthType}
        authType={authType}
        authTypeData={authTypeData}
        status={status}
        appTwoFA={appTwoFA}
        emailTwoFA={emailTwoFA}
      />
    </>
  );
};

export default MainHeader;
