import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody
} from "../../APIHelpers/Responses";

export const documentAIServices = {
  getActiveDocumentCaseList,
  getActiveDocumentMetadataById,
};

// Get All Active Document Case List
async function getActiveDocumentCaseList() {
  const response = await fetch(
    `${config.API_URL}getActiveDocumentCaseList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Active Document Metadata List By Id
async function getActiveDocumentMetadataById(caseId) {
  const response = await fetch(
    `${config.API_URL}getActiveDocumentMetadataById/${caseId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}