import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { documentAIServices } from "../../APIServices/AIAlpha/documentAIServices";
import { creditsAIServices } from "../../APIServices/AIAlpha/creditsAIServices";
import { handleResponse } from "../../APIHelpers/Responses";

const DocumentAITools = ({
  activeBreadcrumb,
  setSteps,
  setCaseName,
  steps,
  setInputText,
  inputText,
  generateAIData,
  loading
}) => {
  const [caseLists, setCaseLists] = useState([])
  const [metadataLists, setMetadataLists] = useState([])
  const [remainingCredits, setRemainingCredits] = useState(0);

  const getActiveDocumentCaseList = async () => {
    let data = await documentAIServices.getActiveDocumentCaseList()
    setCaseLists(data?.data)
  }
  const getActiveDocumentMetadataById = async (caseId) => {
    let data = await documentAIServices.getActiveDocumentMetadataById(caseId)
    setMetadataLists(data?.data)
  }

  const getTotalRemainingCredit = async () => {
    const data = await creditsAIServices.getTotalRemainingCredit();
    setRemainingCredits(data?.remainingCredits);
  };

  const creditNotAvailable = async () => {
    let Message = {
      statusCode: 400,
      customMessage: "Opps! You have insufficient Credits. Please Recharge First.",
    }
    handleResponse(Message);
  };

  useEffect(() => {
    getTotalRemainingCredit();
    getActiveDocumentCaseList();
  }, []);
  return (
    <>
      <Container fluid className="pt-2">
        <Row>
          <Form.Group as={Col} md="12" className="mb-3">
            <Form.Label>Select language</Form.Label>
            <Form.Select aria-label="Default select example" disabled>
              <option value="arabic">🇦🇪 Arabic</option>
              <option value="bulgarian">🇧🇬 Bulgarian</option>
              <option value="english" selected disabled>
                🇺🇸 English
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-3">
            <Form.Label>Choose use case</Form.Label>
            <Form.Select
              disabled={activeBreadcrumb?.type !== "file" || loading}
              onChange={(e) => setCaseName(e.target.value)}
              onClick={(e) => getActiveDocumentMetadataById(e.target[e.target.selectedIndex].id)}
              aria-label="Default select example">
              <option>Choose use case</option>
              {caseLists?.map((item, index) => (
                <option key={index} id={item._id} value={item.caseName}>
                  {item.caseName}
                </option>
              ))}
            </Form.Select>
            <Form.Text>
              Choose use case based on section topics & headlines
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} md="12" className="mb-3">
            <Form.Label>Select Metadata</Form.Label>
            <Form.Select
              onChange={(e) => setSteps(e.target.value)}
              disabled={activeBreadcrumb?.type !== "file" || loading}
              aria-label="Default select example">
              <option>Select Metadata</option>
              {metadataLists?.map((item, index) => (
                <option key={index} value={item.steps}>
                  {item.metadata}
                </option>
              ))}
            </Form.Select>
            <Form.Text>Select Metadata based on case</Form.Text>
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-3">
            <Form.Label>Section keywords</Form.Label>
            <Form.Control
              as="textarea"
              onChange={(e) => setInputText(e.target.value)}
              value={inputText}
              disabled={activeBreadcrumb?.type !== "file" || loading}
              placeholder="Enter keyword according to your metadata"
            />
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-3">
            {remainingCredits === 0 ?
              <button
                onClick={creditNotAvailable}
                disabled={activeBreadcrumb?.type !== "file" || !inputText || steps?.length === 0}
                className="themeButton">
                Write for me
              </button>
              :
              <button
                onClick={generateAIData}
                disabled={activeBreadcrumb?.type !== "file" || !inputText || steps?.length === 0 || loading}
                className="themeButton hstack gap-2">
                Write for me
                <Spinner
                  as="span"
                  animation={loading && "border"}
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </button>
            }
          </Form.Group>
        </Row>
      </Container>
    </>
  );
};

export default DocumentAITools;
