import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const AccountSidebar = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="p-0">
        <div className="Account_sidebar pt-1">
          <ul>
            <NavLink to={"/account/analytics"}>
              <li>
                <div className="hstack gap-1 iconSizes">
                  <i className="fa-solid fa-chart-line me-1"></i>
                  <p className="m-0">Analytics</p>
                </div>
              </li>
            </NavLink>
            <NavLink to={"/account/datasets"}>
              <li>
                <div className="hstack gap-1 iconSizes">
                  <i className="fa-solid fa-database me-1"></i>
                  <p className="m-0">Datasets</p>
                </div>
              </li>
            </NavLink>
            <NavLink to={"/account/transaction"}>
              <li>
                <div className="hstack gap-1 iconSizes">
                  <i className="fa-solid fa-coins me-1"></i>
                  <p className="m-0">Transactions</p>
                </div>
              </li>
            </NavLink>
            <NavLink to={"/account/recharge"}>
              <li>
                <div className="hstack gap-1 iconSizes">
                  <i className="fa-solid fa-file-invoice-dollar me-1"></i>
                  <p className="m-0">Recharge</p>
                </div>
              </li>
            </NavLink>
            <NavLink to={"/account/trash"}>
              <li>
                <div className="hstack gap-1 iconSizes">
                  <i className="fa-solid fa-trash-can me-1"></i>
                  <p className="m-0">Trash</p>
                </div>
              </li>
            </NavLink>
            <NavLink to={"/account/backup"}>
              <li>
                <div className="hstack gap-1 iconSizes">
                  <i className="fa-solid fa-trash-arrow-up me-1"></i>
                  <p className="m-0">Backup</p>
                </div>
              </li>
            </NavLink>
            <li onClick={() => setOpen(!open)} className={`${open ? "activeSidebarSetting" : ""}`}>
              <div className="hstack justify-content-between">
                <div className="hstack gap-1 iconSizes">
                  <i className="fa-solid fa-gear me-1"></i>
                  <p className="m-0">Settings</p>
                </div>
                <i
                  className={`fa-solid me-1 ${
                    !open ? "fa-angle-down" : "fa-angle-up"
                  }`}
                ></i>
              </div>
            </li>
            <Collapse in={open}>
              <ul>
                <NavLink to={"/account/profile"}>
                  <li>
                    <div className="hstack gap-1 iconSizes">
                      <i className="fa-solid fa-address-card me-3 ps-2"></i>
                      <p className="m-0">Profile</p>
                    </div>
                  </li>
                </NavLink>
                <NavLink to={"/account/appearance"}>
                  <li>
                    <div className="hstack gap-1 iconSizes">
                      <i className="fa-solid fa-palette me-3 ps-2"></i>
                      <p className="m-0">Appearance</p>
                    </div>
                  </li>
                </NavLink>
                <NavLink to={"/account/logdata"}>
                  <li>
                    <div className="hstack gap-1 iconSizes">
                      <i className="fa-solid fa-history me-3 ps-2"></i>
                      <p className="m-0">Log Data</p>
                    </div>
                  </li>
                </NavLink>
                <NavLink to={"/account/failedstatus"}>
                  <li>
                    <div className="hstack gap-1 iconSizes">
                      <i className="fa-solid fa-ban me-3 ps-2"></i>
                      <p className="m-0">Failed Status</p>
                    </div>
                  </li>
                </NavLink>
              </ul>
            </Collapse>
          </ul>
        </div>
      </div>
    </>
  );
};
export default AccountSidebar;
