import React, { useEffect, useState } from "react";
import TableHeader from "../TableHeader";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";
import LogTableData from "./LogTableData";
import Pagination from "../../../Hooks/Pagination";
import ExportLogHistoryModal from "./ExportLogHistoryModal";
import UseToggle from "../../../Hooks/useToggle";

const LogData = ({tableRef, onMouseDown}) => {
  const { toggle, setToggle } = UseToggle();
  const [logData, setLogData] = useState([]);
  const getLogRecord = async () => {
    let data = await loginActivitiesServices.getLoginHistoryRecord();
    setLogData(data?.data);
  };
  const itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = logData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(logData?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % logData?.length;
    setItemOffset(newOffset);
  };
  useEffect(() => {
    getLogRecord();
  }, []);

  return (
    <>
      <TableHeader
        Export
        handleExport={setToggle}
        titleExport={"Export Log Data"}
      />
      <div className="sidebar_Content">
        <LogTableData logData={currentItems} onMouseDown={onMouseDown}  tableRef={tableRef}/>
        <Pagination
          Result={logData}
          itemsPerPage={itemsPerPage}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
        />
      </div>
      <ExportLogHistoryModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title="Export Log Data"
        Submit={"Export"}
      />
    </>
  );
};

export default LogData;
