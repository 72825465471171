import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { themeSettingsServices } from "../../../APIServices/themeSettingsServices";

const ChangeColor = ({ getThemeColorData, themeColor }) => {
  const [color, setColor] = useState("");
  const [loader, setLoader] = useState(false);

  document.documentElement.style.setProperty(
    "--accent-200",
    `rgb(${color} / 5%)`
  );
  document.documentElement.style.setProperty("--bg-300", `rgb(${color} / 15%)`);
  document.documentElement.style.setProperty(
    "--accent-200",
    `rgb(${color} / 100%)`
  );

  const updateThemeColorData = async (defaultColor) => {
    setLoader(true);
    let userData = {};
    userData.color = defaultColor || color;
    let data = await themeSettingsServices.updateThemeColorData(userData);
    if (data?.statusCode === 200) {
      getThemeColorData();
      setLoader(false);
    }
  };

  useEffect(() => {
    setColor(themeColor);
  }, [getThemeColorData, themeColor]);

  return (
    <div>
      <Row>
        <Form.Group as={Col}  lg={5} md={12}>
          <Form.Label>Select Theme Color</Form.Label>
          <Form.Select
            className="mb-2"
            onChange={(e) => setColor(e.target.value)}
            aria-label="Default select example"
          >
            <option selected={color === "0 97 154"} value={"0 97 154"}>
              Theme Default
            </option>
            <option selected={color === "1 170 17"} value={"1 170 17"}>
              Green
            </option>
            <option selected={color === "160 0 235"} value={"160 0 235"}>
              Purple
            </option>
            <option selected={color === "72 72 72"} value={"72 72 72"}>
              Black & White
            </option>
          </Form.Select>
          <div className="hstack gap-3">
            <button
              className="themeButton hstack gap-2"
              onClick={() => updateThemeColorData("0 97 154")}
            >
              <Spinner
                as="span"
                animation={loader && "border"}
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Reset
            </button>
            <button
              className="themeButton hstack gap-2"
              onClick={() => updateThemeColorData()}
            >
              <Spinner
                as="span"
                animation={loader && "border"}
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Save
            </button>
          </div>
        </Form.Group>
      </Row>
    </div>
  );
};

export default ChangeColor;
