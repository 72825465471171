import React from "react";

const AlreadyRegisteredMessage = () => {
  return (
    <>
      <div className="Form_justification">
        <div
          className="Invitation_form shadow p-4 text-center"
          style={{ width: 520 }}
        >
          <h3 className="pb-4 m-0 text-center">
            <b>Alredy Registered</b>
          </h3>
          <div className="Success__taag mb-3">
            <i
              style={{ color: "green" }}
              className="fa-solid fa-circle-check"
              alt="Invitation Link Expired"
            ></i>
          </div>
          <span>Your registration alredy completed. </span>
          <span>If you have any query. </span>
          <span>Feel free to contact "Administrator". </span>

          <div className="text-center mt-4">
            <a href="/">
              <button className="themeButton">Login</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlreadyRegisteredMessage;
