import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const TableHeader = ({
  Search,
  handleSearch,
  seachData,
  setSearchData,
  SearchReverseData,
  AddNew,
  handleAddNew,
  titleAddNew,
  titleName,
  Invite,
  Restore,
  handleRestore,
  titleRestore,
  handleInvite,
  titleInvite,
  InviteHistory,
  handleInviteHistory,
  titleInviteHistory,
  SubscriptionTransection,
  handleSubscriptionTransection,
  titleSubscriptionTransection,
  Export,
  handleExport,
  titleExport,
  handleDelete,
  titleDelete,
  Delete,
  ViewAnalylics,
  handleViewAnalyics,
  titleViewAnalyics,
  SelectType,
  handleSelectType,
  SelectTransactionType,
  handleSelectTransactionType,
  copied,
  Requests,
  handleRequest,
  titleRequest,
  AddTitle,
  handleAddTitle,
  titleAddTitle,
  Upload,
  handleUpload,
  titleUpload,
}) => {
  const [filter, setFilter] = useState(false);

  return (
    <div className="hstack justify-content-between mb-2 user_filter pt-2 px-2">
      <div className="hstack gap-2">
        {Search && (
          <>
            {filter ? (
              <>
                <Button
                  onClick={() => {
                    setFilter(false);
                    SearchReverseData();
                  }}
                  title="Cancel"
                  className="close_circle"
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
                <div className="hstack">
                  <Form.Control
                    required
                    value={seachData}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                      setSearchData(e.target.value);
                    }}
                    type="search"
                    placeholder="Search"
                  />
                  <Button
                    title="Search"
                    onClick={() => handleSearch()}
                    style={{ borderRadius: "0px 3px 3px 0px", width: 40 }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </Button>
                </div>
              </>
            ) : (
              <Button onClick={() => setFilter(true)} title="Filter">
                <i className="fa-solid fa-filter"></i>
              </Button>
            )}
          </>
        )}
        {SelectTransactionType && (
          <div className="selectTransaction">
            <Form.Select
              onChange={handleSelectTransactionType}
              aria-label="Select Type"
            >
              <option selected value="all">
                All
              </option>
              <option value="pending">Pending</option>
              <option value="succeeded">Completed</option>
              <option value="canceled">Cancelled</option>
            </Form.Select>
          </div>
        )}
        {SelectType && (
          <div className="selectTransaction">
            <Form.Select onChange={handleSelectType} aria-label="Select Type">
              <option selected value="all">
                All
              </option>
              <option value="documentAI">Document AI</option>
              <option value="programAI">Program AI</option>
              <option value="purchased">Purchased</option>
              <option value="reward">Reward</option>
              <option value="backup">Backup</option>
            </Form.Select>
          </div>
        )}
        {AddTitle && (
          <Button onClick={handleAddTitle} title={titleAddTitle}>
            <div className="hstack gap-2">
              <i className="fa-solid fa-plus"></i>
            </div>
          </Button>
        )}
        {AddNew && (
          <Button onClick={handleAddNew} title={titleAddNew}>
            <div className="hstack gap-2">
              <i className="fa-solid fa-plus"></i>
              <p className="m-0">
                <b>{titleName}</b>
              </p>
            </div>
          </Button>
        )}
         {Requests && (
          <Button onClick={handleRequest} title={titleRequest}>
            <p className="m-0"><b><i className="fa-solid fa-trash-arrow-up" style={{marginRight: "5px"}}></i>Request Backup</b></p>
          </Button>
        )}
        {ViewAnalylics && (
          <Button onClick={handleViewAnalyics} title={titleViewAnalyics}>
            <i className="fa-solid fa-chart-line "></i>
          </Button>
        )}
        {Invite && (
          <Button onClick={handleInvite} title={titleInvite}>
            <i className="fa-solid fa-paper-plane"></i>
          </Button>
        )}
        {SubscriptionTransection && (
          <Button
            onClick={handleSubscriptionTransection}
            title={titleSubscriptionTransection}
          >
            <i className="fa-solid fa-hand-holding-dollar"></i>
          </Button>
        )}
        {InviteHistory && (
          <Button onClick={handleInviteHistory} title={titleInviteHistory}>
            <i className="fa-solid fa-history"></i>
          </Button>
        )}
        {Restore && (
          <Button onClick={handleRestore} title={titleRestore}>
            <i className="fa-solid fa-rotate-left" ></i>
          </Button>
        )}
        {Delete && (
          <Button onClick={handleDelete} title={titleDelete} className="close_circle">
            <i className="fa-solid fa-trash "></i>
          </Button>
        )}
       
        {copied && (
          <span style={{ fontSize: 13, color: "green" }}>
            <b>Copied!</b>
          </span>
        )}
      </div>
      <div className="hstack gap-2">
        {Upload && (
          <Button onClick={handleUpload} title={titleUpload}>
            <i className="fa-solid fa-upload"></i>
          </Button>
        )}
        {Export && (
          <Button onClick={handleExport} title={titleExport}>
            <i className="fa-solid fa-download"></i>
          </Button>
        )}
      </div>
    </div>
  );
};

export default TableHeader;
