import config from "../../config/config.json";
import { headers } from "../../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../APIHelpers/Responses";

export const aiLibraryServices = {
  //Library
  searchLibrary,
  addNewLibraryData,
  updateAILibraryData,
  deleteAILibraryData,
  deleteMultiAILibraryData,
  getAILibraryData,
  getAILibraryDataById,

  //Favorite Library
  searchFavoriteLibrary,
  addFavoriteAILibraryData,
  addMultiFavoriteAILibraryData,
  getFavoriteAILibraryData,
  getFavoriteAILibraryDataById,

  // delete library
  searchDeletedLibrary,

  // Restore Library Data
  restoreAILibraryData,
  restoreMultiAILibraryData,
  calculateAllDeletedData,

  // Delete trash data
  permanetDeletedAILibraryData,
  permanetDeletedMultiAILibraryData,
};

// Search Library
async function searchLibrary(userData) {
  const response = await fetch(
    `${config.API_URL}searchLibrary`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Library
async function addNewLibraryData(userData) {
  const response = await fetch(
    `${config.API_URL}addNewLibraryData`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Library
async function updateAILibraryData(userData) {
  const response = await fetch(
    `${config.API_URL}updateAILibraryData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Multi Restore Library Data
async function restoreMultiAILibraryData(userData) {
  const response = await fetch(
    `${config.API_URL}restoreMultiAILibraryData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Multi Restore Library Data
async function permanetDeletedMultiAILibraryData(userData) {
  const response = await fetch(
    `${config.API_URL}permanetDeletedMultiAILibraryData`,
    headersWithAuth("DELETE", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Restore Library Data
async function restoreAILibraryData(id) {
  const response = await fetch(
    `${config.API_URL}restoreAILibraryData/${id}`,
    headersWithAuth("PUT", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Library List for Table
async function getAILibraryData(path) {
  const response = await fetch(
    `${config.API_URL}getAILibraryData/${path}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Days Deleted Data
async function calculateAllDeletedData(day) {
  const response = await fetch(
    `${config.API_URL}calculateAllDeletedData/${day}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Library List by id for Table
async function getAILibraryDataById(id, path) {
  const response = await fetch(
    `${config.API_URL}getAILibraryDataById/${id}/${path}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Library
async function deleteAILibraryData(id) {
  const response = await fetch(
    `${config.API_URL}deleteAILibraryData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Trash Data
async function permanetDeletedAILibraryData(id) {
  const response = await fetch(
    `${config.API_URL}permanetDeletedAILibraryData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Multiple Library
async function deleteMultiAILibraryData(userData) {
  const response = await fetch(
    `${config.API_URL}deleteMultiAILibraryData`,
    headersWithAuth("DELETE", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Search Favorite Library
async function searchFavoriteLibrary(userData) {
  const response = await fetch(
    `${config.API_URL}searchFavoriteLibrary`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Search Deletd Library
async function searchDeletedLibrary(searchData) {
  const response = await fetch(
    `${config.API_URL}searchDeletedLibrary`,
    headersWithAuth("POST", searchData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Favorite Library
async function addFavoriteAILibraryData(userData) {
  const response = await fetch(
    `${config.API_URL}addFavoriteAILibraryData`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add New Multi Favorite Library
async function addMultiFavoriteAILibraryData(userData) {
  const response = await fetch(
    `${config.API_URL}addMultiFavoriteAILibraryData`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All Favorite Library List for Table
async function getFavoriteAILibraryData(path) {
  const response = await fetch(
    `${config.API_URL}getFavoriteAILibraryData/${path}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Favorite Library List by id for Table
async function getFavoriteAILibraryDataById(id, path) {
  const response = await fetch(
    `${config.API_URL}getFavoriteAILibraryDataById/${id}/${path}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
