import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { aiLibraryServices } from "../../../APIServices/AIAlpha/aiLibraryServices";

const AddFileFolderModal = ({ Show, Hide, Size, Title, Submit, type, folderId, getAILibraryData }) => {
  let [name, setName] = useState("");
  const addNewLibraryData = async () => {
    let userData = {};
    userData.name = name;
    userData.type = type;
    userData.path = "program";
    if (folderId) {
      userData.folderId = folderId;
    }
    let data = await aiLibraryServices.addNewLibraryData(userData);
    if (data?.statusCode) {
      cleanAndHideData();
      if(folderId){
        getAILibraryData("mainLibrary", folderId)
      }else{
        getAILibraryData("mainLibrary")
      }
    }
  };

  const cleanAndHideData = () => {
    setName("");
    Hide();
  }
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={cleanAndHideData} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="mb-2">
            <Form.Label className="text-capitalize">
              {type} Name <span>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e)=>setName(e.target.value)}
              value={name}
              placeholder={`Enter ${type === "folder" ? "Folder" : "File"} Name`}
              autoFocus
            />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-end">
          <button
            className="themeButton"
            disabled={!name}
            onClick={addNewLibraryData}
          >
            {Submit}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFileFolderModal;
