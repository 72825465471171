import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import UpdateStudyIdModal from "./UpdateStudyIdModal";
import Moment from "react-moment";
import { datasetsServices } from "../../../APIServices/AIAlpha/datasetsServices";
import Swal from "sweetalert2";
import UseToggle from "../../../Hooks/useToggle";

const StudyIdTableData = ({
  studyListList,
  setViewDatasets,
  getDataSetsByProgramStudyId,
  getProgramStudyId,
  tableRef,
  onMouseDown,
}) => {
  const { toggle, setToggle } = UseToggle();
  const ref = useRef(null);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [studyLabel, setStudyLabel] = useState("");

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Study Id?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.studyLabel = studyLabel;
        let data = await datasetsServices.updateProgramStudyId(userData);
        if (data?.statusCode === 200) {
          getProgramStudyId();
        }
      }
    });
  };

  const updateStudyIdModal = (item) => {
    setStudyLabel(item.studyLabel);
    setId(item._id);
    setToggle();
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this document?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await datasetsServices.deleteProgramStudyId(id);
        if (data?.statusCode === 200) {
          getProgramStudyId();
        }
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div
        className="table-container overflow-auto tableLibrarySection"
        style={{ maxHeight: "calc(100vh - 180px)" }}
      >
        <Table
          ref={tableRef}
          id="resizable-table"
          className="custom-table"
          hover
          bordered
        >
          <thead>
            <tr>
              <th style={{ width: 40 }} onMouseDown={(e) => onMouseDown(e, 0)}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Study</th>
              <th style={{ width: 140 }} onMouseDown={(e) => onMouseDown(e, 2)}>
                Updated Time
              </th>
              <th style={{ width: 80 }} onMouseDown={(e) => onMouseDown(e, 3)}>
                Action
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {studyListList?.length > 0 ? (
              studyListList?.map((item, index) => (
                <tr className="position-relative" key={index}>
                  <td>{index + 1}</td>
                  <td title={item.studyLabel}>{item.studyLabel}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td>
                    <div className="text-center hstack gap-1 action__buttons">
                      <Button
                        onClick={() => {
                          setViewDatasets(item.studyLabel);
                          getDataSetsByProgramStudyId(item._id);
                        }}
                        title="View"
                        className="rounded-circle"
                      >
                        <i class="fa-solid fa-eye"></i>
                      </Button>
                      <Button
                        onClick={() => updateStudyIdModal(item)}
                        title="Edit"
                        className="rounded-circle"
                      >
                        <i class="fa-solid fa-pen-to-square"></i>
                      </Button>
                      <Button
                        onClick={(e) => handleDelete(e, item._id)}
                        title="Delete"
                        className="rounded-circle close_circle"
                      >
                        <i class="fa-solid fa-trash-can"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="relative text-center">
                <td colSpan={4}>
                  <div className="noRecordFound">Record not found</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <UpdateStudyIdModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Update Study Id"}
        setStudyLabel={setStudyLabel}
        studyLabel={studyLabel}
        handleUpdate={handleUpdate}
        Submit={"Update"}
      />
    </>
  );
};

export default StudyIdTableData;
