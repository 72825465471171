import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LoginImage from "../../image/LoginPage.gif";
import { invitedDataServices } from "../../APIServices/inviteAndRegisterServices";
import { addressServices } from "../../APIServices/addressServices";
import SignUpSuccess from "./SignUpSuccess";

const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phone, setPhone] = useState("");
  const [cityLists, setCityLists] = useState([]);
  const [city_id, setCityID] = useState("");
  const [stateLists, setStateLists] = useState([]);
  const [state_id, setStateID] = useState("");
  const [countryLists, setCountryLists] = useState([]);
  const [country_id, setCountryID] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [statusCode, setStatusCode] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async (e) => {
    let data = await addressServices.getCountries(e);
    setCountryLists(data.data);
  };

  const getStates = async (e) => {
    let data = await addressServices.getStates(e);
    setStateLists(data.data);
  };

  const getCities = async (e) => {
    let data = await addressServices.getCities(e);
    setCityLists(data.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userData = {};
    userData.firstName = firstName;
    userData.middleName = middleName;
    userData.lastName = lastName;
    userData.address = address;
    userData.email = email;
    userData.gender = gender;
    userData.phone = phone;
    userData.cityId = city_id;
    userData.stateId = state_id;
    userData.countryId = country_id;
    userData.zipCode = postalCode;
    userData.roleType = "aiuser";
    let data = await invitedDataServices.userRegistration(userData);
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setGender("");
      setEmail("");
      setPhone("");
      setAddress("");
      setPostalCode("");
      setCityID("");
      setStateID("");
      setCountryID("");
      setStatusCode(true);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
    } else {
      setEmailError("Invalid Email, Please Enter Valid Email!");
    }
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number should have 10 digits ");
    }
  };

  return (
    <>
      {statusCode ? (
        <SignUpSuccess />
      ) : (
        <Container fluid>
          <Row>
            <Col className="p-0" lg={7} sm={12} md={12}>
              <div className="LoginImg">
                <img src={LoginImage} alt="LoginPic" />
              </div>
            </Col>
            <Col className="p-0 login_page" lg={5} sm={12} md={12}>
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <section className="sign-up">
                    <h5 className="text-center mb-3">
                      <b>Sign Up</b>
                    </h5>
                    <Row>
                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>
                          First Name<span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter first name"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>Middle Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter middle name"
                          onChange={(e) => setMiddleName(e.target.value)}
                          value={middleName}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Last name"
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>
                          Email<span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            validateEmail(e.target.value);
                          }}
                          value={email}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>
                          Phone Number<span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Phone number"
                          onChange={(e) => {
                            setPhone(e.target.value);
                            validatePhoneNumber(e.target.value);
                          }}
                          value={phone}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>Gender</Form.Label>
                        <Form.Select
                          onChange={(e) => setGender(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option>Select Gender</option>
                          <option selected={gender === "male"} value="male">
                            Male
                          </option>
                          <option selected={gender === "female"} value="female">
                            Female
                          </option>
                          <option selected={gender === "other"} value="other">
                            Other
                          </option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group as={Col} md="12" className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter your address"
                          style={{ height: "29px" }}
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>
                          Country<span>*</span>
                        </Form.Label>
                        <Form.Select
                          onChange={(e) => setCountryID(e.target.value)}
                          value={country_id}
                          onClick={(e) =>
                            getStates(e.target[e.target.selectedIndex].title)
                          }
                        >
                          <option>-Select-</option>
                          {countryLists.length > 0
                            ? countryLists.map((item, key) => (
                                <option
                                  value={item._id}
                                  key={key}
                                  title={item.id}
                                >
                                  {item.name}
                                </option>
                              ))
                            : ""}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>
                          State<span>*</span>
                        </Form.Label>
                        <Form.Select
                          onChange={(e) => setStateID(e.target.value)}
                          value={state_id}
                          onClick={(e) =>
                            getCities(e.target[e.target.selectedIndex].title)
                          }
                        >
                          <option>-Select-</option>
                          {stateLists.length > 0
                            ? stateLists.map((item, key) => (
                                <option
                                  value={item._id}
                                  key={key}
                                  title={item.id}
                                >
                                  {item.name}
                                </option>
                              ))
                            : ""}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>
                          City<span>*</span>
                        </Form.Label>
                        <Form.Select
                          onChange={(e) => setCityID(e.target.value)}
                          value={city_id}
                        >
                          <option>-Select-</option>
                          {cityLists.length > 0
                            ? cityLists.map((item, key) => (
                                <option value={item._id} key={key}>
                                  {item.name}
                                </option>
                              ))
                            : ""}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>
                          Zip Code<span>*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="Enter Postel Code"
                          onChange={(e) => setPostalCode(e.target.value)}
                          value={postalCode}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="12">
                        <button
                          className="mb-3 themeButton w-100"
                          disabled={
                            !firstName ||
                            !address ||
                            !phone ||
                            !email ||
                            !city_id ||
                            !state_id ||
                            !country_id ||
                            !postalCode ||
                            emailError ||
                            phoneError
                          }
                          onClick={handleSubmit}
                        >
                          Sign Up
                        </button>
                        <div className="d-flex justify-content-between">
                          {emailError || phoneError ? (
                            <div>
                              <p
                                className="message m-0"
                                style={{ color: "red" }}
                              >
                                * {emailError}
                              </p>
                              <p
                                className="message m-0"
                                style={{ color: "red" }}
                              >
                                * {phoneError}
                              </p>
                            </div>
                          ) : (
                            <p
                              className="message"
                              style={
                                statusMessage?.statusCode === 200
                                  ? { color: "green" }
                                  : { color: "red" }
                              }
                            >
                              {statusMessage?.customMessage}
                            </p>
                          )}
                          <p className="message" title="Click for Login Panel">
                            <NavLink to="/login">
                              <b>Already have Account? Login</b>
                            </NavLink>
                          </p>
                        </div>
                      </Form.Group>
                    </Row>
                  </section>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default SignUp;
