import React from "react";
import { Card, Col, Form, FormGroup, Modal, Row } from "react-bootstrap";
import TrashChartData from "./TrashChartData";

const TrashDeletedItemAnalysisChartModal = ({
  Show,
  Hide,
  Size,
  Title,
  themeColor,
  calculateAllDeletedData,
  allDayData,
}) => {
  const daysArray = Array.from({ length: 30 }, (_, i) => i + 1);
  return (
    <>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col md={12} className="mb-3">
              <div className="d-flex justify-content-between">
                <div>
                  <FormGroup className="mb-3 hstack gap-2">
                    <Form.Label className="m-0" style={{ fontSize: 14 }}>
                      Total Deleted Items: {allDayData?.totalDeleted}
                    </Form.Label>
                  </FormGroup>
                </div>
                <div className="d-flex gap-2">
                  <Form.Select
                    onChange={(e) => calculateAllDeletedData(e.target.value)}
                    aria-label="Default select example"
                    style={{ height: 32 }}
                  >
                    <option selected value={"all"}>
                      Total Data
                    </option>
                    {daysArray.map((day) => (
                      <option key={day} value={day}>
                        {day} {day === 1 ? "day" : "days"}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            </Col>

            <Col md={7}>
              <Card
                className="shadow-sm border-0 p-2 mb-2"
                style={{ height: 250 }}
              >
                <Card.Body>
                  <TrashChartData themeColor={themeColor} graphData={allDayData?.graphData}/>
                </Card.Body>
              </Card>
            </Col>
            <Col md={5}>
              <Card
                className="shadow-sm border-0 p-2 mb-1"
                style={{ height: 250 }}
              >
                <Card.Body>
                  <p style={{ color: "red" }}>
                    "You will lost your data within 30 days from deleted Date"
                  </p>
                  <h6 style={{ fontSize: "14px" }} className="mb-5">
                    <b>Total Deleted Data Convert into credits: </b>
                  </h6>
                  <div className="text-center" style={{ color: "#0c4a6e" }}>
                    <h6 className="shadow-lg border-0 rounded p-2">
                      <b>{allDayData?.totalCredit || 0} Credits</b>
                    </h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TrashDeletedItemAnalysisChartModal;
