import config from "../../config/config.json";
import axios from "axios";
import { headers } from "../../APIHelpers/Headers";
import {
  headersWithAuth,
  headersWithAuthWithoutBody
} from "../../APIHelpers/Responses";

export const AIServices = {
  //Program AI
  addNewAIQueryResult,
  getAIData,
  getAIProgramData,
  getAIDocumentData,

  //Generative Data
  generateAIData
};

// Add New Program AI Query & Result
async function addNewAIQueryResult(userData) {
  const response = await fetch(
    `${config.API_URL}addNewAIQueryResult`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get AI Data
async function getAIData(fileId) {
  const response = await fetch(
    `${config.API_URL}getAIData/${fileId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Program AI Data
async function getAIProgramData() {
  const response = await fetch(
    `${config.API_URL}getAIProgramData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Document AI Data
async function getAIDocumentData() {
  const response = await fetch(
    `${config.API_URL}getAIDocumentData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// AI Register User
async function generateAIData(formData) {
  const response = await axios.post(
    `https://tklw9bcnq6.execute-api.us-east-1.amazonaws.com/prod/galaxalpha`,
    formData,
    {
      headers: {
        'x-api-key': 'svqkFDx7rk35Bq3voYp8C1ltF6QluaX94QjsM2NM',
        'Content-Type': 'application/json',
      },
    }
  );
  const data = response?.data;
  return data;
}
