import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AITableHeader from "../../AITableHeader";
import AIUser from "../../../image/aiuser.png";

const DocumentAIOutputResult = ({
  setBreadcrumbData,
  breadcrumbData,
  activeBreadcrumb,
  setActiveBreadcrumb,
  setId,
  getAILibraryData,
  documentList,
  cleanToolsData
}) => {
  const [resultData, setResultData] = useState("");
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const Editor = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const formatContent = (content) => {
    const paragraphs = content?.split("\n\n");
    let formattedContent = "";
    paragraphs?.forEach((paragraph, index) => {
      if (/^\d+\./.test(paragraph)) {
        paragraph = paragraph?.replace(/^(\d+\.\s)/, "<strong>$1</strong>");
        paragraph += "<br>";
      }
      paragraph = paragraph?.replace(/\*\*(.*?)\*\*/g, "<br><h2>$1</h2>");
      paragraph = paragraph?.replace(/\*(.*?)\*/g, "<strong>$1</strong>");
      formattedContent += paragraph;
      if (index < paragraphs.length - 1) {
        formattedContent += "<br>";
      }
    });

    return formattedContent;
  };

  useEffect(() => {
    let results = "";
    documentList?.reverse()?.forEach((item) => {
      results += item.result;
    });

    setResultData(formatContent(results) || "");
  }, [documentList]);
  return (
    <>
      <AITableHeader
        cleanToolsData={cleanToolsData}
        SearchReverseData={getAILibraryData}
        setBreadcrumbData={setBreadcrumbData}
        breadcrumbData={breadcrumbData}
        activeBreadcrumb={activeBreadcrumb}
        setActiveBreadcrumb={setActiveBreadcrumb}
        setId={setId}
      />
      {resultData ? (
        <div className="sidebar_Content">
          <ReactQuill
            modules={modules}
            formats={Editor}
            theme="snow"
            value={resultData}
            onChange={setResultData}
          />
        </div>
      ) : (
        <div className="autoProgramHeight d-grid">
          <div
            className="para_details NullImages text-center"
            style={{ placeSelf: "center" }}
          >
            <img src={AIUser} alt="" />
            <h4 className="mt-4">
              <b>How can i help with you?</b>
            </h4>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentAIOutputResult;
