import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import DocumentAITools from "./DocumentAITools";
import DocumentLibrary from "./DocumentLibrary/DocumentLibrary";
import MainHeader from "../MainHeader";
import Footer from "../Footer";
import DocumentAIOutputResult from "./DocumentOutputResult/DocumentAIOutputResult";
import { aiLibraryServices } from "../../APIServices/AIAlpha/aiLibraryServices";
import DocumentFavoriteLibrary from "./DocumentFavoriteLibrary/DocumentFavoriteLibrary";
import { AIServices } from "../../APIServices/AIAlpha/AIServices";

const DocumentAICreate = ({ userCurrrentData, tableRef, onMouseDown }) => {
  const [library, setLibrary] = useState([]);
  const [inputText, setInputText] = useState("");
  const [caseName, setCaseName] = useState("");
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [libriryType, setLibriryType] = useState(
    JSON.parse(localStorage.getItem("libriryType")) || "mainLibrary"
  );
  const [activeBreadcrumb, setActiveBreadcrumb] = useState(
    JSON.parse(localStorage.getItem("activeDocumentBreadcrumbData")) || {}
  );

  const [breadcrumbData, setBreadcrumbData] = useState(
    JSON.parse(localStorage.getItem("breadcrumbDocumentData")) || []
  );

  const [id, setId] = useState(activeBreadcrumb?._id || "");

  const handleChangeLibraryType = (e, libriryType) => {
    setLibriryType(libriryType);
    localStorage.setItem("libriryType", JSON.stringify(libriryType));
    getAILibraryData(libriryType, id);
  };

  const getAILibraryData = async (libriryType, id) => {
    let data;
    if (libriryType === "mainLibrary") {
      if (id) {
        data = await aiLibraryServices.getAILibraryDataById(id, "document");
      } else {
        data = await aiLibraryServices.getAILibraryData("document");
      }
    } else {
      if (id) {
        data = await aiLibraryServices.getFavoriteAILibraryDataById(
          id,
          "document"
        );
      } else {
        data = await aiLibraryServices.getFavoriteAILibraryData("document");
      }
    }
    setLibrary(data?.data);
  };

  const generateAIData = async () => {
    setLoading(true);
    let userPrompt = `Write a Content for ${inputText} use following ${steps}. I want heading, bold, line break formatted content based on html tags Code`;
    const formData = new FormData();
    formData.append("prompt", userPrompt);
    let data = await AIServices.generateAIData(formData);
    if (data) {
      addNewAIQueryResult(data?.generated_text, data?.total_tokens);
    }
  };

  const addNewAIQueryResult = async (response, tokens) => {
    let userData = {};
    userData.fileId = id;
    userData.caseName = caseName;
    userData.query = inputText;
    userData.result = response;
    userData.tokens = tokens || 0;
    let data = await AIServices.addNewAIQueryResult(userData);
    if (data?.statusCode === 200) {
      getAIData(id);
      setLoading(false);
    }
  };

  const cleanToolsData = () => {
    setCaseName("");
    setInputText("");
    setSteps([]);
  };

  const getAIData = async (id) => {
    let data = await AIServices.getAIData(id);
    setDocumentList(data?.data);
  };

  useEffect(() => {
    getAILibraryData(libriryType, id);
    getAIData(id);
  }, [libriryType, id]);
  return (
    <>
      <MainHeader userCurrrentData={userCurrrentData} />
      <Container fluid>
        <Row>
          <Col md={3} className="p-0">
            <div className="sidebar_cpm">
              <div className="program_aim">
                <Form.Label className="m-0">
                  <i className="fa-solid fa-file me-2"></i>Document AI
                </Form.Label>
              </div>
              <div className="sidebar_Content">
                <DocumentAITools
                  activeBreadcrumb={activeBreadcrumb}
                  setSteps={setSteps}
                  steps={steps}
                  setInputText={setInputText}
                  inputText={inputText}
                  generateAIData={generateAIData}
                  setCaseName={setCaseName}
                  loading={loading}
                />
              </div>
            </div>
          </Col>
          <Col md={9} className="p-0">
            {activeBreadcrumb?.type !== "file" ? (
              libriryType === "mainLibrary" ? (
                <DocumentLibrary
                  tableRef={tableRef}
                  onMouseDown={onMouseDown}
                  id={id}
                  setId={setId}
                  setBreadcrumbData={setBreadcrumbData}
                  breadcrumbData={breadcrumbData}
                  activeBreadcrumb={activeBreadcrumb}
                  setActiveBreadcrumb={setActiveBreadcrumb}
                  handleChangeLibraryType={handleChangeLibraryType}
                  getAILibraryData={getAILibraryData}
                  library={library}
                  setLibrary={setLibrary}
                  cleanToolsData={cleanToolsData}
                />
              ) : (
                <DocumentFavoriteLibrary
                  tableRef={tableRef}
                  onMouseDown={onMouseDown}
                  id={id}
                  setId={setId}
                  setBreadcrumbData={setBreadcrumbData}
                  breadcrumbData={breadcrumbData}
                  activeBreadcrumb={activeBreadcrumb}
                  setActiveBreadcrumb={setActiveBreadcrumb}
                  handleChangeLibraryType={handleChangeLibraryType}
                  getAILibraryData={getAILibraryData}
                  library={library}
                  setLibrary={setLibrary}
                  cleanToolsData={cleanToolsData}
                />
              )
            ) : (
              <DocumentAIOutputResult
                id={id}
                setId={setId}
                setBreadcrumbData={setBreadcrumbData}
                breadcrumbData={breadcrumbData}
                activeBreadcrumb={activeBreadcrumb}
                setActiveBreadcrumb={setActiveBreadcrumb}
                getAILibraryData={getAILibraryData}
                documentList={documentList}
                cleanToolsData={cleanToolsData}
              />
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default DocumentAICreate;
