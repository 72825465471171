import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const BillingTableData = ({
  getBillingTransections,
  setCopied,
  tableRef,
  onMouseDown,
}) => {
  const copyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "succeeded":
        return "green";
      case "pending":
        return "orange";
      case "canceled":
        return "red";
      case "failed":
        return "darkred";
      default:
        return "inherit";
    }
  };

  return (
    <>
      <div className="table-container overflow-auto tableLibrarySection">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="custom-table"
          hover
          bordered
        >
          <thead>
            <tr>
              <th style={{ width: 40 }} onMouseDown={(e) => onMouseDown(e, 0)}>
                Sr
              </th>
              <th style={{ width: 100 }} onMouseDown={(e) => onMouseDown(e, 1)}>
                Credits
              </th>
              <th style={{ width: 60 }} onMouseDown={(e) => onMouseDown(e, 2)}>
                Price
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Transaction ID</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Payment ID</th>
              <th style={{ width: 200 }} onMouseDown={(e) => onMouseDown(e, 5)}>
                Transaction Date | Time
              </th>
              <th style={{ width: 150 }} onMouseDown={(e) => onMouseDown(e, 6)}>
                Status
              </th>
              <th style={{ width: 60 }} onMouseDown={(e) => onMouseDown(e, 7)}>
                Invoice
              </th>
            </tr>
          </thead>
          <tbody>
            {getBillingTransections?.map((item, index) => (
              <tr key={index} className="position-relative">
                <td>{index + 1}</td>
                <td>
                  <i className="fa-solid fa-coins me-1"></i>
                  {item.credit}
                </td>
                <td>
                  {item.currency}
                  {item.payment}
                </td>
                <td>
                  <div className="hstack gap-2">
                    <div className="transactionId" title={item.transactionId}>
                      {item.transactionId}
                    </div>
                    <div
                      className="hstack justify-content-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => copyToClipboard(item.transactionId)}
                      title="Copy Trasaction Id"
                    >
                      <i className="fa-regular fa-copy"></i>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="hstack gap-2">
                    <div className="paymentId" title={item.paymentId}>
                      {item.paymentId || "N/A"}
                    </div>
                    {item.paymentId && (
                      <div
                        className="hstack justify-content-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => copyToClipboard(item.paymentId)}
                        title="Copy Payment Id"
                      >
                        <i className="fa-regular fa-copy"></i>
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  <Moment format="DD MMM YYYY | HH:ss">{item.createdAt}</Moment>
                </td>
                <td>
                  <div
                    style={{ color: getStatusColor(item.status) }}
                    className="text-capitalize"
                  >
                    <b>
                      {item.status === "pending"
                        ? "Pending"
                        : item.status === "succeeded"
                        ? "Completed"
                        : "Cancelled"}
                    </b>
                    {item.status === "pending" && (
                      <a
                        href={item.paymentUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          className="themeButton p-0 px-2 ms-2"
                          title="Pay for Pending Transaction"
                          style={{ fontSize: 10 }}
                        >
                          Pay
                        </button>
                      </a>
                    )}
                  </div>
                </td>
                <td>
                  <div className="text-center">
                    {item.status === "succeeded" ? (
                      <a
                        href={`/invoiceReceipt/${item.transactionId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          className="themeButton rounded-circle p-1 px-2"
                          title="View Invice"
                          style={{ fontSize: 10, width: 20, height: "18.5px" }}
                        >
                          <div className="hstack justify-content-center">
                            <i className="fa-solid fa-eye"></i>
                          </div>
                        </button>
                      </a>
                    ) : (
                      <button
                        className="themeButton rounded-circle p-1 px-2"
                        title="disabled"
                        style={{ fontSize: 10, width: 20, height: "18.5px" }}
                        disabled
                      >
                        <div className="hstack justify-content-center">
                          <i className="fa-solid fa-eye"></i>
                        </div>
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};
export default BillingTableData;
