import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { creditsAIServices } from "../../../../APIServices/AIAlpha/creditsAIServices";
import DocumentChart from "./DocumentChart";
import WordChart from "./WordChart";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import { AIServices } from "../../../../APIServices/AIAlpha/AIServices";

const AIDocumentComponent = ({ themeColor }) => {
  const [creditTransectionData, setCreditTransectionData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [totalCaseName, setTotalCaseName] = useState([]);
  const [totalDocument, setTotalDocument] = useState([]);
  const [wordData, setWordData] = useState([]);
  const [totalWordDocument, setTotalWordDocument] = useState([]);
  const [totalWord, setTotalWord] = useState([]);
  const [totalUsedCredit, setTotalUsedCredit] = useState(0);
  const getcreditTransectionData = async (type) => {
    let data = await creditsAIServices.getCreditTransectionByDate(type);
    const selectedData = data?.data.slice(0, 2);
    setCreditTransectionData(selectedData);
  };

  const getCreditTransectionByFilter = async (range) => {
    let data = await creditsAIServices.getCreditTransectionByFilter(
      "documentAI",
      range
    );
    setTotalUsedCredit(data?.data);
  };

  const getAIDocumentData = async () => {
    let data = await AIServices.getAIDocumentData();
    setDocumentData(data?.documentResult);
    setTotalCaseName(data?.totalDocCase);
    setTotalDocument(data?.totalDocument);
    setWordData(data?.wordResult);
    setTotalWordDocument(data?.totalWordDocument);
    setTotalWord(data?.totalWord);
  };

  useEffect(() => {
    getAIDocumentData();
    getcreditTransectionData("documentAI");
    getCreditTransectionByFilter("today");
  }, []);

  return (
    <>
      <Container fluid>
        <div className="graph__title">
          <p style={{ fontSize: 17 }}>
            <b>AI Document :</b>
          </p>
        </div>
        <Row className="mt-2">
          {documentData?.map((item, index) => (
            <Col xl={3} lg={4} md={6} sm={12} key={index} className="mb-3">
              <Card className="shadow-sm border-0 h-100">
                <Card.Body>
                  <div className="hstack">
                    <div className="icon rounded-circle">
                      <i className="fa-solid fa-file-contract"></i>
                    </div>
                    <div className="widget-data">
                      <Card.Title className="text-muted">
                        {item.totalDocument}
                      </Card.Title>
                      <Card.Text>
                        <b>Total Generated {item.caseName} Document</b>
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
          {wordData?.map((item, index) => (
            <Col xl={3} lg={4} md={6} sm={12} key={index} className="mb-3">
              <Card className="shadow-sm border-0 h-100">
                <Card.Body>
                  <div className="hstack">
                    <div className="icon rounded-circle">
                      <i className="fa-solid fa-w"></i>
                    </div>
                    <div className="widget-data">
                      <Card.Title className="text-muted">
                        {item.wordCount}
                      </Card.Title>
                      <Card.Text>
                        <b>Total Generated {item.caseName} Words</b>
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
          <Col xl={4} lg={6} md={6} sm={12} className="mb-3">
            <Card className="shadow-sm border-0 h-100">
              <Card.Body>
                <DocumentChart
                  totalCaseName={totalCaseName}
                  totalDocument={totalDocument}
                  themeColor={themeColor}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col xl={4} lg={6} md={6} sm={12} className="mb-3">
            <Card className="shadow-sm border-0 h-100">
              <Card.Body>
                <WordChart
                  totalWord={totalWord}
                  totalWordDocument={totalWordDocument}
                  themeColor={themeColor}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col xl={4} lg={12} md={12} sm={12} className="mb-3">
            <Card className="shadow-sm border-0 h-100">
              <Card.Body>
                <div className="graph__title">
                  <Card.Text className="m-0">
                    <b>AI Document Credit Detail</b>
                  </Card.Text>
                  <hr style={{ color: "var(--border_color)" }} />
                  <div className="hstack justify-content-between mb-2">
                    <Card.Text className="m-0">
                      <b>Filter By : </b>
                    </Card.Text>
                    <Card.Text className="m-0">
                      <Form.Select
                        aria-label="Default select example"
                        className="filterDropdown"
                        required
                        onChange={(e) =>
                          getCreditTransectionByFilter(e.target.value)
                        }
                      >
                        <option selected value="today">
                          Today
                        </option>
                        <option value="previousDay">Yesterday </option>
                        <option value="lastSevenDays">Last 7 Day's</option>
                        <option value="currentMonth">This Month</option>
                        <option value="lastThreeMonths">Last 3 Month</option>
                        <option value="lastSixMonths">Last 6 Month</option>
                        <option value="currentYear">This Year</option>
                      </Form.Select>
                    </Card.Text>
                  </div>
                  <div className="hstack justify-content-between mb-2">
                    <Card.Text className="m-0">
                      <b>Total Used Credits : </b>
                    </Card.Text>
                    <Card.Text className="m-0 text-danger">
                      <b>
                        {totalUsedCredit}
                        <i className="fa-solid fa-coins ms-1"></i>
                      </b>
                    </Card.Text>
                  </div>
                  <hr
                    className="mb-0"
                    style={{ color: "var(--border_color)" }}
                  />
                  <div className="transactionTable">
                    <Table>
                      <thead>
                        <tr>
                          <th>Credits</th>
                          <th style={{ width: 100 }}>Used Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {creditTransectionData?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <i className="fa-solid fa-coins me-1"></i>
                              {item.credit}
                            </td>
                            <td>
                              <Moment format="DD MMM YYYY">
                                {item.createdAt}
                              </Moment>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <hr style={{ color: "var(--border_color)" }} />
                  <div className="text-end">
                    <NavLink to={"/account/transaction"}>
                      <button className="themeButton p-2 py-1">
                        View All Credit Transactions
                      </button>
                    </NavLink>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AIDocumentComponent;
