import config from "../../config/config.json";
import { headers } from "../../APIHelpers/Headers";
import {
  headersWithAuthWithoutBody
} from "../../APIHelpers/Responses";

export const programAIServices = {
  //Program AI
  getActiveProgramLanguage,
  getActiveProgramPrompts
};

// Get Program Languages
async function getActiveProgramLanguage() {
  const response = await fetch(
    `${config.API_URL}getActiveProgramLanguage`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Program Prompts
async function getActiveProgramPrompts(programId) {
  const response = await fetch(
    `${config.API_URL}getActiveProgramPrompts/${programId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}