import React from "react";

const ExpiredInvitationMessage = () => {
  return (
    <>
      <div className="Form_justification">
        <div
          className="Invitation_form shadow p-4 text-center"
          style={{ width: 520 }}
        >
          <h3 className="pb-4 m-0 text-center">
            <b>Expired Invitation Link</b>
          </h3>
          <div className="Success__taag mb-3">
            <i
              style={{ color: "red" }}
              className="fa-solid fa-circle-xmark"
              alt="Invitation Link Expired"
            ></i>
          </div>
          <span>
            Your Invitation link has been expired. Please contact
            "<b>Administrator</b>". After that check your mail, then you are able to
            Registration
          </span>
        </div>
      </div>
    </>
  );
};

export default ExpiredInvitationMessage;
