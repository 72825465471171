import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ProgramChart = ({ themeColor, totalLanguage, totalProgram }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  let totalData = totalProgram.reduce((total, currentValue) => total + currentValue, 0);

  useEffect(() => {
    const colorData = themeColor ? themeColor : "0 97 154";
    const colors = colorData.split(" ").map((color) => parseInt(color));
    setChartData({
      series: totalProgram,
      options: {
        chart: {
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 580,
            options: {
              chart: {
                width: 200,
                height: 200,
              },
            },
          },
        ],
        labels: totalLanguage,
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Math.round((val * totalData)/100);
          },
          style: {
            fontSize: "12px",
          },
        },
        colors: [
          `rgba(${colors.join(", ")}, 1)`,
          `rgba(${colors.join(", ")}, 0.65)`,
          `rgba(${colors.join(", ")}, 0.3)`,
          `rgba(${colors.join(", ")}, 0.1)`,
        ],
        plotOptions: {
          pie: {
            donut: {
              size: "45%",
            },
          },
        },
      },
    });
  }, [themeColor, totalLanguage, totalProgram]);

  return (
    <div className="graph__title">
      <div id="chart">
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="donut"
          height="300"
        />
      </div>
    </div>
  );
};

export default ProgramChart;