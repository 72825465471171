import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const ViewTransactionDetailsTable = ({ creditTransectionData }) => {
  return (
    <div>
      <Table hover>
        <thead>
          <tr>
            <th style={{ width: 40 }}>Sr</th>
            <th>Credits</th>
            <th>Transaction Time</th>
          </tr>
        </thead>
        <tbody>
          {creditTransectionData?.map((item, index) => (
            <tr key={index} className="position-relative">
              <td>{index + 1}</td>
              <td>
                <i className="fa-solid fa-coins me-1"></i>
                {item.credit}
              </td>
              <td>
                <Moment format="HH:mm:ss">{item.createdAt}</Moment>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ViewTransactionDetailsTable;
