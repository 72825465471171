import React, { useEffect, useState } from "react";
import TableHeader from "../TableHeader";
import BackupTableData from "./BackupTableData";
import UseToggle from "../../../Hooks/useToggle";
import Pagination from "../../../Hooks/Pagination";
import BackupExportModal from "./BackupExportModal";
import BackupRequestModal from "./BackupRequestModal";
import { backupAIServices } from "../../../APIServices/AIAlpha/backupAIServices";

const Backup = ({ tableRef, onMouseDown }) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();
  const [backupData, setBackupData] = useState([]);
  const [fromDateTime, setFromDateTime] = useState("");
  const [toDateTime, setToDateTime] = useState("");
  const [storeDateTime, setStoreDateTime] = useState("");

  const getAIRequestBackupData = async () => {
    let data = await backupAIServices.getAIRequestBackupData();
    setBackupData(data?.data);
  };

  const itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = backupData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(backupData?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % backupData?.length;
    setItemOffset(newOffset);
  };

  const calculateBackupDataPrice = async () => {
    let userData = {};
    userData.fromDateTime = fromDateTime;
    userData.toDateTime = toDateTime;
    let data = await backupAIServices.calculateBackupDataPrice(userData);
    setStoreDateTime(data);
  };

  const openCalculateBackupPrice = () => {
    setToggle1()
    calculateBackupDataPrice()
  }

  useEffect(() => {
    getAIRequestBackupData();
  }, []);

  return (
    <>
      <TableHeader
        Requests
        handleRequest={openCalculateBackupPrice}
        titleRequest={"Request Backup"}
        Export
        handleExport={setToggle}
        titleExport={"Export Backup Data"}
      />
      <div className="sidebar_Content">
        <BackupTableData
          backupData={currentItems}
          onMouseDown={onMouseDown}
          tableRef={tableRef}
        />
        <Pagination
          Result={backupData}
          itemsPerPage={itemsPerPage}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
        />
      </div>
      <BackupExportModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title="Export Backup Data"
        Submit={"Export"}
      />
      <BackupRequestModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"lg"}
        Title="Request Backup Data"
        calculateBackupDataPrice={calculateBackupDataPrice}
        fromDateTime={fromDateTime}
        toDateTime={toDateTime}
        storeDateTime={storeDateTime}
        setFromDateTime={setFromDateTime}
        setToDateTime={setToDateTime}
        getAIRequestBackupData={getAIRequestBackupData}
      />
    </>
  );
};

export default Backup;
