/**
 * Set localStorage
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  return window.localStorage.setItem(name, content);
};

/**
 * Get localStorage
 */
export const getStore = (name) => {
  if (!name) return;
  return JSON.parse(window.localStorage.getItem(name));
};

/**
 * Clear localStorage
 */
export const removeItem = (name) => {
  if (!name) return;
  return window.localStorage.removeItem(name);
};

/**
 * Validate Email address
 */
export const isValidEmail = (value) => {
  return !(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(value));
};

/**
 * Format Phone Number
 */
export const formatPhoneNumber = (value) => {
  if (!value) return;
  const currentValue = value.replace(/[^\d]/g, "");
  const mobileNoLength = currentValue.length;
  if (mobileNoLength >= 7) {
    if (mobileNoLength < 4) return currentValue;
    if (mobileNoLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  } else {
    return currentValue;
  }
};

export const handleCopyText = (selectedArea, setCopySuccess) => {
  const range = document.createRange();
  range.selectNode(selectedArea);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);

  try {
    const success = document.execCommand('copy');
    if (success) {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 6000); // Clear success message after 2 seconds
    }
  } catch (err) {
    console.error('Copy failed:', err);
  }

  window.getSelection().removeAllRanges();
};

export const convertDatetoDay = (date) => {
  let dateData = date?.split("-")
  const planYear = dateData?.[2];
  const planMonth = dateData?.[1];
  const planDay = dateData?.[0];

  // Get the current date
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
  const currentDay = String(currentDate.getDate()).padStart(2, '0');

  const year = Number(planYear) - Number(currentYear)
  const month = Number(planMonth) - Number(currentMonth)
  const day = Number(planDay) - Number(currentDay)

  const totalDay = year * 365 + month * 30 + day

  return totalDay;
}