import React, { useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { creditsAIServices } from "../../../APIServices/AIAlpha/creditsAIServices";

const PaymentCancel = () => {
  let { uniqueId } = useParams();

  const confirmPaymentStatus = async () => {
    let userData = {
      uniqueId: uniqueId,
    };
    await creditsAIServices.confirmPaymentStatus(userData);
  };

  useEffect(() => {
    confirmPaymentStatus();
  }, []);

  return (
    <>
      <div className="thanksSection">
        <div className="p-0 login_page" md={5}>
          <Card className="shadow-sm border-0  w-25">
            <Card.Body>
              <section className="sign-up m-auto py-3">
                <div className="Success__taag mb-3">
                  <i
                    style={{ color: "red" }}
                    className="fa-solid fa-circle-xmark"
                    alt="Invitation Link Expired"
                  ></i>
                </div>
                <h5 className="text-center mb-3">
                  <b>Payment Cancelled.</b>
                </h5>

                <Row>
                  <Form.Group as={Col} md="12" className="mb-3 text-center">
                    <Card.Text>
                      Opps! your payment is cancelled. Please try again.
                    </Card.Text>
                  </Form.Group>
                  <Form.Group as={Col} md="12" className="text-center">
                    <NavLink to={"/account/recharge"}>
                      <button className="mb-3 themeButton w-50">
                        Back to Billing
                      </button>
                    </NavLink>
                  </Form.Group>
                </Row>
              </section>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default PaymentCancel;
