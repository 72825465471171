import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import ProgramAITools from "./ProgramAITools";
import MainHeader from "../MainHeader";
import Footer from "../Footer";
import ProgramLibrary from "./ProgramLibrary/ProgramLibrary";
import ProgramAIOutputResult from "./ProgramOutputResult/ProgramAIOutputResult";
import ProgramFavoriteLibrary from "./ProgramFavoriteLibrary/ProgramFavoriteLibrary";
import { aiLibraryServices } from "../../APIServices/AIAlpha/aiLibraryServices";
import { AIServices } from "../../APIServices/AIAlpha/AIServices";

const ProgramAICreate = ({ userCurrrentData, tableRef, onMouseDown }) => {
  const [inputText, setInputText] = useState("");
  const [steps, setSteps] = useState([]);
  const [languages, setLanguages] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [programList, setProgramList] = useState([]);
  const [selectedDatasetList, setSelectedDatasetList] = useState([]);
  const [allDatasetList, setAllDatasetList] = useState([]);
  const [datasetList, setDatasetList] = useState([]);
  const [promptList, setPromptList] = useState([]);
  const [library, setLibrary] = useState([]);
  const [libriryType, setLibriryType] = useState(
    JSON.parse(localStorage.getItem("libriryType")) || "mainLibrary"
  );
  const [activeBreadcrumb, setActiveBreadcrumb] = useState(
    JSON.parse(localStorage.getItem("activeProgramBreadcrumbData")) || {}
  );

  const [breadcrumbData, setBreadcrumbData] = useState(
    JSON.parse(localStorage.getItem("breadcrumbProgramData")) || []
  );

  const [id, setId] = useState(activeBreadcrumb?._id || "");

  const handleChangeLibraryType = (e, libriryType) => {
    setLibriryType(libriryType);
    localStorage.setItem("libriryType", JSON.stringify(libriryType));
    getAILibraryData(libriryType, id);
  };

  const getAILibraryData = async (libriryType, id) => {
    let data;
    if (libriryType === "mainLibrary") {
      if (id) {
        data = await aiLibraryServices.getAILibraryDataById(id, "program");
      } else {
        data = await aiLibraryServices.getAILibraryData("program");
      }
    } else {
      if (id) {
        data = await aiLibraryServices.getFavoriteAILibraryDataById(
          id,
          "program"
        );
      } else {
        data = await aiLibraryServices.getFavoriteAILibraryData("program");
      }
    }
    setLibrary(data?.data);
  };

  const generateAIData = async () => {
    setLoading(true);
    let result = allDatasetList?.reduce((acc, item) => {
      if (!acc[item.Datasets]) {
        acc[item.Datasets] = [];
      }
      acc[item.Datasets].push(`${item.Variables} (${item.Labels})`);
      return acc;
    }, {});

    let matchedData = {};

    selectedDatasetList?.forEach((key) => {
      if (result[key]) {
        matchedData[key] = result[key];
      }
    });
    matchedData = Object.entries(matchedData)
      .map(
        ([dataset, columns]) =>
          `${dataset} has following Column [${columns.join(", ")}]`
      )
      .join(", ");

    let userPrompt = `Write a ${languages} Program for ${inputText} ${type} use following datasets (${selectedDatasetList?.join(
      ", "
    )}). ${matchedData}, ${steps}`;

    const formData = new FormData();
    formData.append("prompt", userPrompt);
    let data = await AIServices.generateAIData(formData);
    if (data) {
      addNewAIQueryResult(data?.generated_text, data?.total_tokens);
    }
  };

  const addNewAIQueryResult = async (response, tokens) => {
    let userData = {};
    userData.fileId = id;
    userData.language = languages;
    userData.query = inputText;
    userData.result = response;
    userData.tokens = tokens;
    let data = await AIServices.addNewAIQueryResult(userData);
    if (data?.statusCode === 200) {
      getAIData(id);
      setLoading(false);
    }
  };

  const cleanToolsData = () => {
    setLanguages("");
    setInputText("");
    setType("");
    setSteps([]);
    setSelectedDatasetList("");
    setPromptList([]);
    setDatasetList([]);
  };

  const getAIData = async (id) => {
    let data = await AIServices.getAIData(id);
    setProgramList(data?.data);
  };

  useEffect(() => {
    getAILibraryData(libriryType, id);
    getAIData(id);
  }, [id, libriryType]);

  return (
    <>
      <MainHeader userCurrrentData={userCurrrentData} />
      <Container fluid>
        <Row>
          <Col md={3} className="p-0">
            <div className="sidebar_cpm">
              <div className="program_aim">
                <Form.Label className="m-0">
                  <i className="fa-solid fa-laptop-code me-2"></i>Program AI
                </Form.Label>
              </div>
              <ProgramAITools
                activeBreadcrumb={activeBreadcrumb}
                setInputText={setInputText}
                inputText={inputText}
                setLanguages={setLanguages}
                languages={languages}
                setType={setType}
                type={type}
                generateAIData={generateAIData}
                selectedDatasetList={selectedDatasetList}
                setSelectedDatasetList={setSelectedDatasetList}
                setAllDatasetList={setAllDatasetList}
                allDatasetList={allDatasetList}
                setSteps={setSteps}
                steps={steps}
                promptList={promptList}
                setPromptList={setPromptList}
                setDatasetList={setDatasetList}
                datasetList={datasetList}
                loading={loading}
              />
            </div>
          </Col>
          <Col md={9} className="p-0">
            {activeBreadcrumb?.type !== "file" ? (
              libriryType === "mainLibrary" ? (
                <ProgramLibrary
                  tableRef={tableRef}
                  onMouseDown={onMouseDown}
                  id={id}
                  setId={setId}
                  setBreadcrumbData={setBreadcrumbData}
                  breadcrumbData={breadcrumbData}
                  activeBreadcrumb={activeBreadcrumb}
                  setActiveBreadcrumb={setActiveBreadcrumb}
                  handleChangeLibraryType={handleChangeLibraryType}
                  getAILibraryData={getAILibraryData}
                  library={library}
                  setLibrary={setLibrary}
                  cleanToolsData={cleanToolsData}
                />
              ) : (
                <ProgramFavoriteLibrary
                  tableRef={tableRef}
                  onMouseDown={onMouseDown}
                  id={id}
                  setId={setId}
                  setBreadcrumbData={setBreadcrumbData}
                  breadcrumbData={breadcrumbData}
                  activeBreadcrumb={activeBreadcrumb}
                  setActiveBreadcrumb={setActiveBreadcrumb}
                  handleChangeLibraryType={handleChangeLibraryType}
                  getAILibraryData={getAILibraryData}
                  library={library}
                  setLibrary={setLibrary}
                  cleanToolsData={cleanToolsData}
                />
              )
            ) : (
              <ProgramAIOutputResult
                id={id}
                setId={setId}
                setBreadcrumbData={setBreadcrumbData}
                breadcrumbData={breadcrumbData}
                activeBreadcrumb={activeBreadcrumb}
                setActiveBreadcrumb={setActiveBreadcrumb}
                getAILibraryData={getAILibraryData}
                programList={programList}
                cleanToolsData={cleanToolsData}
              />
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ProgramAICreate;
