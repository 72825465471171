import React, { useRef, useState } from "react";
import { authServices } from "../../../APIServices/authServices";

const TwoFactorAuthByEmail = ({ emailTwoFA, setNextBy, setModalSize, getTwoFactorAuthType }) => {
  const [OTPSent, setOTPSent] = useState(false)
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const otpInputs = useRef([]);

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]$/.test(value)) {
      const updatedValues = [...otpValues];
      updatedValues[index] = value;
      setOtpValues(updatedValues);

      if (value !== "" && index < 5) {
        otpInputs.current[index + 1].focus();
      }

      if (value === "" && index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };

  const sendOTPForEmailTwoFa = async () => {
    let data = await authServices.emailOTPFor2FA()
    if (data?.statusCode === 200) {
      setOTPSent(true)
      setModalSize(true)
    }
  }

  const enableTwoFactorAuthByEmail = async () => {
    let userData = {}
    userData.otp = otpValues
    let data = await authServices.enableTwoFactorAuthByEmail(userData)
    if (data?.statusCode === 200) {
      getTwoFactorAuthType()
      setOTPSent(false)
      setOtpValues(["", "", "", "", "", ""])
      setModalSize(false)
    }
  }

  const disableTwoFactorAuthByEmail = async () => {
    let userData = {}
    userData.otp = otpValues
    let data = await authServices.disableTwoFactorAuthByEmail(userData)
    if (data?.statusCode === 200) {
      getTwoFactorAuthType()
      setOTPSent(false)
      setNextBy(true)
      setOtpValues(["", "", "", "", "", ""])
      setModalSize(false)
    }
  }
  return (
    <>
      {!emailTwoFA ?
        <>
          {!OTPSent ?
            <>
              <div className="qr__imag mb-3">
                <div className="security-shield">
                  <i className="fa-solid fa-shield-halved"></i>
                  <p className="m-0"><b>Disabled</b></p>
                </div>
              </div>
              <p style={{ fontSize: 12, textAlign: "center" }}>To enable Email 2FA, send OTP to the registered email address. If you click on Send OTP button, OTP will be automatically sent to your registered email address.</p>
              <div className="hstack gap-3 justify-content-end pt-2">
               <button className="themeButton" onClick={() => setNextBy(false)}>Back</button>
               <button className="themeButton" onClick={() => sendOTPForEmailTwoFa()}>{"Send OTP"}</button>
              </div>
            </>
            :
            <>
              <label className="small mb-1" for="inputCountry">
                Enter OTP
              </label>
              <div className="otp-input-fields px-0 justify-content-start">
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    pattern="\d*"
                    maxLength="1"
                    className={`otp__digit otp__field__${index + 1}`}
                    value={value}
                    onChange={(e) => handleInputChange(index, e)}
                    ref={(input) => (otpInputs.current[index] = input)}
                  />
                ))}
              </div>
              <div className="hstack gap-3 justify-content-end pt-2">
               <button className="themeButton" onClick={() => { setOTPSent(false); setOtpValues(["", "", "", "", "", ""]); setModalSize(false) }}>Back</button>
               <button className="themeButton" onClick={() => enableTwoFactorAuthByEmail()}>Enable</button>
              </div>
            </>
          }
        </>
        :
        <>
          {!OTPSent ?
            <>
              <div className="qr__imag">
                <div className="security-shield">
                  <i className="fa-solid fa-shield-halved"></i>
                  <p className="m-0"><b>Enabled (2FA by Email)</b></p>
                </div>
              </div>
              <div className="hstack gap-3 justify-content-center pt-3">
               <button className="themeButton" onClick={() => sendOTPForEmailTwoFa()}>Disable</button>
              </div>
            </>
            :
            <>
              <label className="small mb-1" for="inputCountry">
                Enter OTP
              </label>
              <div className="otp-input-fields px-0 justify-content-start">
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    pattern="\d*"
                    maxLength="1"
                    className={`otp__digit otp__field__${index + 1}`}
                    value={value}
                    onChange={(e) => handleInputChange(index, e)}
                    ref={(input) => (otpInputs.current[index] = input)}
                  />
                ))}
              </div>
              <div className="hstack gap-3 justify-content-end pt-2">
               <button className="themeButton" onClick={() => { setOTPSent(false); setModalSize(false) }}>Back</button>
               <button className="themeButton" onClick={() => disableTwoFactorAuthByEmail()}>Disable 2FA</button>
              </div>
            </>
          }
        </>
      }
    </>
  );
};

export default TwoFactorAuthByEmail;
