import React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import LoginImage from "../../image/LoginPage.gif";
import { NavLink } from "react-router-dom";

const SignUpSuccess = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="p-0" lg={7} sm={12} md={12}>
            <div className="LoginImg">
              <img src={LoginImage} alt="LoginPic" />
            </div>
          </Col>
          <Col className="p-0 login_page" lg={5} sm={12} md={12}>
            <Card className="shadow-sm border-0">
              <Card.Body>
                <section className="sign-up w-75 m-auto py-5">
                  <h5 className="text-center mb-3">
                    <b>Thanks! your account has been successfully created.</b>
                  </h5>
                  <Row>
                    <Form.Group as={Col} md="12" className="mb-3 text-center">
                      <Card.Text>
                        Please check your inbox, your <b>login credentials</b>{" "}
                        is sent on your <b>email</b>, which will be require for{" "}
                        <b>Login </b> your account.
                      </Card.Text>
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="text-center">
                      <NavLink to={"/"}>
                        <button className="mb-3 themeButton w-50">
                          Back to Login
                        </button>
                      </NavLink>
                    </Form.Group>
                  </Row>
                </section>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default SignUpSuccess;
