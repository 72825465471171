import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="mt-5">
        <div className="row">
          <div className="col-md-2 text-center">
            <p>
              <i
                style={{ color: "#fe696a" }}
                className="fa fa-exclamation-triangle fa-5x"
              ></i>
              <br />
              <b>Status Code: 404</b>
            </p>
          </div>
          <div className="col-md-10">
            <h4>
              <b>404 Page not Found</b>
            </h4>
            <p>
              Sorry, your access has been denied or you have not been granted
              access to this page due to security reasons for our servers and
              our sensitive data. Please contact the administrator.
              <br />
              <br />
              The Page you were looking for does not exist.
            </p>
            <div>
              <button onClick={() => navigate(-1)} className="themeButton">
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
