import React, { useEffect, useState } from "react";
import TableHeader from "../TableHeader";
import UseToggle from "../../../Hooks/useToggle";
import ExportTransectionDetailsModal from "./ExportTransectionDetailsModal";
import AddCreditsModal from "./AddCreditsModal";
import Pagination from "../../../Hooks/Pagination";
import BillingTableData from "./BillingTableData";
import { creditsAIServices } from "../../../APIServices/AIAlpha/creditsAIServices";
import ViewAnalyticsChartModal from "./ViewAnalyticsChartModal";

const CreditRecharge = ({ themeColor, tableRef, onMouseDown }) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle3, setToggle3 } =
    UseToggle();
  const [transectionData, setTransectionData] = useState([]);
  const [copied, setCopied] = useState(false);

  const getBillingTransections = async (type) => {
    let data = await creditsAIServices.getBillingTransections(type);
    setTransectionData(data?.data);
  };

  const itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = transectionData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(transectionData?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % transectionData?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    getBillingTransections("all");
  }, []);

  return (
    <>
      <TableHeader
        SelectTransactionType
        handleSelectTransactionType={(e) =>
          getBillingTransections(e.target.value)
        }
        AddNew
        handleAddNew={setToggle}
        titleAddNew={"Add Credits"}
        titleName={"Add Credits"}
        ViewAnalylics
        handleViewAnalyics={setToggle3}
        titleViewAnalyics={"View Analytics"}
        copied={copied}
        Export
        handleExport={setToggle1}
        titleExport={"Export Tansection Data"}
      />
      <div className="sidebar_Content">
        <BillingTableData
          getBillingTransections={currentItems}
          setCopied={setCopied}
          onMouseDown={onMouseDown}
          tableRef={tableRef}
        />
        <Pagination
          itemsPerPage={itemsPerPage}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
        />
      </div>
      <AddCreditsModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title="Add Credits"
        Submit={"Checkout"}
      />
      <ViewAnalyticsChartModal
        Show={!toggle3}
        Hide={setToggle3}
        Size={"lg"}
        Title="View Billing Analytics"
        themeColor={themeColor}
      />
      <ExportTransectionDetailsModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title="Export Transection Data"
        Submit={"Export"}
      />
    </>
  );
};
export default CreditRecharge;
