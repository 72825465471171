import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="header_section footer_section">
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="hstack justify-content-between">
              <NavLink to={"/home"}>
                <p className="m-0">
                  <b>Galax® Alpha</b>
                </p>
              </NavLink>
              <p className="m-0">
                <b>V 1.0.0</b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
