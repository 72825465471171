import React, { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LoginImage from "../../image/LoginPage.gif";
import { authServices } from "../../APIServices/authServices";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      e.stopPropagation();
      let userData = {};
      userData.email = email;
      userData.password = password;
      userData.roles = ["aiuser"];
      let data = await authServices.login(userData);
      setStatusMessage(data);
      setTimeout(() => {
        setStatusMessage("");
      }, 3000);
    }
    setValidated(true);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="p-0" lg={7} sm={12} md={12} >
            <div className="LoginImg">
              <img src={LoginImage} alt="LoginPic" />
            </div>
          </Col>
          <Col className="p-0 login_page" lg={5} sm={12} md={12}>
            <Card className="shadow-sm border-0">
              <Card.Body>
                <section className="sign-up">
                  <h5 className="text-center">
                    <b>Login</b>
                  </h5>
                  <Row>
                    <Form.Group as={Col} md="12" className="mb-3">
                      <Form.Label>
                        Email <span>*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email ID"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-3">
                      <Form.Label>
                        Password <span>*</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          required
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter Password"
                        />
                        <div
                          className="passwordvisiability"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i
                              className="fa-regular fa-eye-slash"
                              title="Hide Password"
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-eye"
                              title="Revel Password"
                            ></i>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-3">
                      <div className="text-end">
                        <p
                          className="message"
                          title="Click here to Reset Your Password"
                        >
                          <NavLink to="/forget-password">
                            <b> Forgot your password?</b>
                          </NavLink>
                        </p>
                      </div>
                      <button
                        className="mb-3 themeButton w-100"
                        type="submit"
                        disabled={!email || !password}
                        validated={validated}
                        onClick={(e) => handleSubmit(e)}
                      >
                        Login
                      </button>
                      <div className="d-flex justify-content-between">
                        <p
                          className="message"
                          style={
                            statusMessage?.statusCode === 200
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          {statusMessage?.customMessage}
                        </p>
                        <p
                          className="message"
                          title="Click to Create Your Account"
                        >
                          <NavLink to="/sign-up">
                            <b>Create Account? Sign Up</b>
                          </NavLink>
                        </p>
                      </div>
                    </Form.Group>
                  </Row>
                </section>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Login;
