import React from "react";
import { useParams } from "react-router-dom";
import {
  Appearance,
  Backup,
  CreditRecharge,
  CreditTransactions,
  Dashboard,
  Datasets,
  FailedStatus,
  LogData,
  Profile,
  Trash,
} from "./Component/Account";
import MainHeader from "./Component/MainHeader";
import { Col, Container, Row } from "react-bootstrap";
import AccountSidebar from "./Component/Account/AccountSidebar";
import Footer from "./Component/Footer";

const AllRoutesData = ({
  userCurrrentData,
  currentUserData,
  themeColor,
  getThemeColor,
  getThemeFont,
  font,
  getStatusColor,
  activeColor,
  inActiveColor,
  pendingColor,
  onMouseDown,
  tableRef,
}) => {
  const { permalink } = useParams();

 
  return (
    <>
      <MainHeader userCurrrentData={userCurrrentData} />
      <Container fluid>
        <Row>
          <Col lg={2} md={3} sm={3} className="p-0">
            <AccountSidebar />
          </Col>
          <Col lg={10} md={9} sm={9} className="p-0">
            {permalink === "analytics" ? (
              <Dashboard themeColor={themeColor} />
            ) : permalink === "profile" ? (
              <Profile
                userCurrrentData={userCurrrentData}
                currentUserData={currentUserData}
              />
            ) : permalink === "recharge" ? (
              <CreditRecharge onMouseDown={onMouseDown} tableRef={tableRef} />
            ) : permalink === "trash" ? (
              <Trash onMouseDown={onMouseDown} tableRef={tableRef} themeColor={themeColor}/>
            ) : permalink === "backup" ? (
              <Backup onMouseDown={onMouseDown} tableRef={tableRef} />
            ) : permalink === "datasets" ? (
              <Datasets onMouseDown={onMouseDown} tableRef={tableRef} />
            ) : permalink === "transaction" ? (
              <CreditTransactions
                themeColor={themeColor}
                onMouseDown={onMouseDown}
                tableRef={tableRef}
              />
            ) : permalink === "appearance" ? (
              <Appearance
                getThemeColor={getThemeColor}
                themeColor={themeColor}
                getThemeFont={getThemeFont}
                font={font}
                getStatusColor={getStatusColor}
                activeColor={activeColor}
                inActiveColor={inActiveColor}
                pendingColor={pendingColor}
              />
            ) : permalink === "logdata" ? (
              <LogData onMouseDown={onMouseDown} tableRef={tableRef} />
            ) : permalink === "failedstatus" ? (
              <FailedStatus onMouseDown={onMouseDown} tableRef={tableRef} />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
export default AllRoutesData;
