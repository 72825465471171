import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { datasetsServices } from "../../../../APIServices/AIAlpha/datasetsServices";

const ExportDatasetsModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  studyId
}) => {
  let [extension, setExtension] = useState("");
  const exportAllDatasetsMultiFormat = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.extension = extension;
    let data = await datasetsServices.exportAllDatasetsMultiFormat(userData);
    if (data?.data) {
      cleanInputBox();
    }
  };

  const cleanInputBox = () => {
    Hide()
    setExtension("")
  }
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={cleanInputBox} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field mb-3">
            <Form.Label>
              Format <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setExtension(e.target.value)}
              aria-label="Select Format"
            >
              <option>Select Format</option>
              <option value="csv">CSV</option>
              <option value="excel">Excel</option>
            </Form.Select>
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer className="py-1 px-3">
        <div className="text-end footer_button">
          <button
            className="themeButton"
            disabled={!extension}
            onClick={exportAllDatasetsMultiFormat}
          >
            {Submit}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportDatasetsModal;
