import React, { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import LoginImage from "../../image/LoginPage.gif";
import { authServices } from "../../APIServices/authServices";

const ResetPassword = () => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const resetPassword = async () => {
    let userData = {};
    userData.token = token;
    userData.newPassword = newPassword;
    userData.repeatPassword = repeatPassword;
    let data = await authServices.resetPassword(userData);
    if (data?.statusCode === 200) {
      resetInputField();
      window.location.href = "/";
    }
  };

  const [error, setError] = useState("");

  const validatePassword = (input) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;

    if (!passwordRegex.test(input)) {
      setError(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
      );
    } else {
      setError("");
    }
  };

  const resetInputField = () => {
    setNewPassword("");
    setRepeatPassword("");
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="p-0" lg={7} sm={12} md={12}>
            <div className="LoginImg">
              <img src={LoginImage} alt="LoginPic" />
            </div>
          </Col>
          <Col className="p-0 login_page" lg={5} sm={12} md={12}>
            <Card className="shadow-sm border-0">
              <Card.Body>
                <section className="sign-up">
                  <h5 className="text-center">
                    <b> Reset Password</b>
                  </h5>
                  <Row>
                    <Form.Group as={Col} md="12" className="mb-3">
                      <Form.Label>
                        New Password <span>*</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          required
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter New Password"
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                            validatePassword(e.target.value);
                          }}
                          value={newPassword}
                        />
                        <div
                          className="passwordvisiability"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i className="fa-regular fa-eye-slash"></i>
                          ) : (
                            <i className="fa-regular fa-eye"></i>
                          )}
                        </div>
                      </div>
                      {!newPassword ? (
                        ""
                      ) : error ? (
                        <span
                          style={{
                            fontSize: 10,
                            color: "red",
                            fontWeight: 600,
                          }}
                        >
                          Password Must have 1 Capital Letter, 1 Small Letter, 1
                          Special Character with 8 Character
                        </span>
                      ) : (
                        <span
                          style={{
                            fontSize: 10,
                            color: "green",
                            fontWeight: 600,
                          }}
                        >
                          Strong Password
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-3">
                      <Form.Label>
                        Confirm Password <span>*</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          required
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Enter Password"
                          onChange={(e) => setRepeatPassword(e.target.value)}
                          value={repeatPassword}
                        />
                        <div
                          className="passwordvisiability"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {showConfirmPassword ? (
                            <i className="fa-regular fa-eye-slash"></i>
                          ) : (
                            <i className="fa-regular fa-eye"></i>
                          )}
                        </div>
                      </div>
                      {newPassword === repeatPassword ? (
                        newPassword?.length === 0 ? (
                          ""
                        ) : (
                          <span
                            style={{
                              fontSize: 10,
                              color: "green",
                              fontWeight: 600,
                            }}
                          >
                            Password Match
                          </span>
                        )
                      ) : (
                        <span
                          style={{
                            fontSize: 10,
                            color: "red",
                            fontWeight: 600,
                          }}
                        >
                          Password Not Match
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="12">
                      <div className="hstack gap-3 mb-2">
                        <button
                          className="themeButton w-100"
                          onClick={resetInputField}
                        >
                          Reset
                        </button>
                        {error || newPassword !== repeatPassword ? (
                          <button className="themeButton w-100">
                            {"Reset Password"}
                          </button>
                        ) : (
                          <button
                            className="themeButton w-100"
                            onClick={resetPassword}
                          >
                            {"Reset Password"}
                          </button>
                        )}
                      </div>

                      <div className="text-end">
                        <p className="message">
                          <a href="/">
                            <b>Back to Login</b>
                          </a>
                        </p>
                      </div>
                    </Form.Group>
                  </Row>
                </section>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ResetPassword;
