import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { themeSettingsServices } from "../../../APIServices/themeSettingsServices";

const ChangeFont = ({ getThemeFontData, fontData }) => {
  const [font, setFont] = useState("");
  const [loader, setLoader] = useState(false);
  document.documentElement.style.setProperty("--check_font_family", font);

  const updateThemeFontData = async (defaultFont) => {
    setLoader(true);
    let userData = {};
    userData.fontName = defaultFont || font;
    let data = await themeSettingsServices.updateThemeFontData(userData);
    if (data?.statusCode === 200) {
      getThemeFontData();
      setLoader(false);
    }
  };

  useEffect(() => {
    setFont(fontData);
  }, [getThemeFontData, fontData]);

  return (
    <div>
      <Row>
        <Form.Group as={Col} lg={5} md={12}>
          <Form.Label>Select Font Family</Form.Label>
          <Form.Select
            className="mb-2"
            onChange={(e) => setFont(e.target.value)}
            aria-label="Default select example"
          >
            <option
              selected
              style={{ fontFamily: `"Gilroy", serif` }}
              value={`"Gilroy", serif`}
            >
              Theme Default
            </option>
            <option
              style={{ fontFamily: `"Lato", sans-serif` }}
              value={`"Lato", sans-serif`}
            >
              Lato
            </option>
            <option
              style={{ fontFamily: `"Josefin Sans", sans-serif` }}
              value={`"Josefin Sans", sans-serif`}
            >
              Josefin Sans
            </option>
            <option
              style={{ fontFamily: `"Oswald", sans-serif` }}
              value={`"Oswald", sans-serif`}
            >
              Oswald
            </option>
            <option
              style={{ fontFamily: `'Itim', cursive` }}
              value={`'Itim', cursive`}
            >
              Itim
            </option>
            <option
              style={{ fontFamily: `'Karla', sans-serif` }}
              value={`'Karla', sans-serif`}
            >
              Karla
            </option>
            <option
              style={{ fontFamily: `'Dancing Script', cursive` }}
              value={`'Dancing Script', cursive`}
            >
              Dancing Script
            </option>
            <option
              style={{ fontFamily: `'Zeyada', cursive` }}
              value={`'Zeyada', cursive`}
            >
              Zeyada
            </option>
            <option
              style={{ fontFamily: `'Great Vibes', cursive` }}
              value={`'Great Vibes', cursive`}
            >
              Great Vibes
            </option>
            <option
              style={{ fontFamily: `'Nanum Pen Script', cursive` }}
              value={`'Nanum Pen Script', cursive`}
            >
              Nanum Pen Script
            </option>
            <option
              style={{ fontFamily: `'Gloria Hallelujah', cursive` }}
              value={`'Gloria Hallelujah', cursive`}
            >
              Gloria Hallelujah
            </option>
            <option
              style={{ fontFamily: `'Lacquer', system-ui` }}
              value={`'Lacquer', system-ui`}
            >
              Lacquer
            </option>
            <option
              style={{ fontFamily: `'Lancelot', serif` }}
              value={`'Lancelot', serif`}
            >
              Lancelot
            </option>
          </Form.Select>
          <Form.Label>Font Result</Form.Label>
          <div className="mb-3 p-3 leaveOptionBox border-0">
            <div className="checkFontClass">
              <p className="m-0">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Accusantium vel provident veniam magnam inventore dignissimos
                commodi veritatis repudiandae corporis itaque!
              </p>
            </div>
          </div>
          <div className="hstack gap-2">
            <button
              className="themeButton hstack gap-2"
              onClick={() => updateThemeFontData(`"Gilroy", serif`)}
            >
              <Spinner
                as="span"
                animation={loader && "border"}
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Reset
            </button>
            <button
              className="themeButton hstack gap-2"
              onClick={() => updateThemeFontData()}
            >
              <Spinner
                as="span"
                animation={loader && "border"}
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Save
            </button>
          </div>
        </Form.Group>
      </Row>
    </div>
  );
};

export default ChangeFont;
