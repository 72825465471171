import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../APIHelpers/Responses";
import axios from "axios";

export const datasetsServices = {
  //Datasets Services
  searchProgramStudyId,
  addNewProgramStudyId,
  updateProgramStudyId,
  deleteProgramStudyId,
  getProgramStudyId,
  uploadDatasetsByStudyId,
  getDataSetsByProgramStudyId,
  exportAllDatasetsMultiFormat
};

// Search Program StudyId
async function searchProgramStudyId(userData) {
  const response = await fetch(
    `${config.API_URL}searchProgramStudyId`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Add New Program StudyId
async function addNewProgramStudyId(userData) {
  const response = await fetch(
    `${config.API_URL}addNewProgramStudyId`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Program StudyId
async function updateProgramStudyId(userData) {
  const response = await fetch(
    `${config.API_URL}updateProgramStudyId`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Delete Program StudyId
async function deleteProgramStudyId(id) {
  const response = await fetch(
    `${config.API_URL}deleteProgramStudyId/${id}`,
    headersWithAuthWithoutBody("Delete", headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Program StudyId
async function getProgramStudyId() {
  const response = await fetch(
    `${config.API_URL}getProgramStudyId`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Upload Datasets by Program StudyId
async function uploadDatasetsByStudyId(formData) {
  const response = await axios.post(
    `${config.API_URL}uploadDatasetsByStudyId`,
    formData
  );
  const data = response?.data;
  await handleResponse(data);
  return data;
}

// Get Dataset by Program StudyId
async function getDataSetsByProgramStudyId(studyId) {
  const response = await fetch(
    `${config.API_URL}getDataSetsByProgramStudyId/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Export Datasets in Multiformat
async function exportAllDatasetsMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportAllDatasetsMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}