import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const TrashChartData = ({ themeColor, graphData }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });
  let sum = graphData?.reduce((total, currentValue) => total + currentValue, 0);
  useEffect(() => {
    const colorData = themeColor ? themeColor : "0 97 154";
    const colors = colorData.split(" ").map((color) => parseInt(color));
    if (Array.isArray(graphData)) {
      setChartData({
        series: graphData,
        options: {
          chart: {
            type: "donut",
          },
          legend: {
            position: "bottom",
          },
          responsive: [
            {
              breakpoint: 500,
              options: {
                chart: {
                  width: 150,
                  height: 150,
                },
              },
            },
          ],
          labels: ["Folders", "Files", "Data"],
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return Math.round((val * sum) / 100);
            },
            style: {
              fontSize: "12px",
            },
          },
          colors: [
            `rgba(${colors.join(", ")}, 1)`,
            `rgba(${colors.join(", ")}, 0.65)`,
            `rgba(${colors.join(", ")}, 0.3)`,
          ],
          plotOptions: {
            pie: {
              donut: {
                size: "40%",
              },
            },
          },
        },
      });
    }
  }, [graphData]);

  return (
    <div className="graph__title">
      <div id="chart">
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="donut"
          height="250"
        />
      </div>
    </div>
  );
};

export default TrashChartData;
