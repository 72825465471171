import React from "react";

const InviteConfirmation = () => {
  return (
    <>
      <div className="Form_justification">
        <div
          className="Invitation_form shadow p-4 text-center"
          style={{ width: 520 }}
        >
          <h3 className="pb-4 m-0 text-center">
            <b>Confirmation</b>
          </h3>
          <div className="Success__taag mb-3">
            <i
              style={{ color: "green" }}
              className="fa-solid fa-circle-check"
              alt="Registration Successful"
            ></i>
          </div>
          <span>You have been successfully registered. </span>
          <span> Please wait for "Administrator" Approval. </span>
          <span> After that check your mail, then you are able to login </span>
          <div className="text-center mt-4">
            <a href="/">
              <button className="themeButton">Login</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteConfirmation;
