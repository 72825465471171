import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import ViewTransactionDetailsModal from "./ViewTransactionDetailsModal/ViewTransactionDetailsModal";
import UseToggle from "../../../Hooks/useToggle";

const CreditTransactionsTable = ({
  creditTransectionData,
  tableRef,
  onMouseDown,
}) => {
  const [type, setType] = useState("");
  const [id, setId] = useState("");

  const hanldeView = (type, id) => {
    setId(id);
    setType(type);
    setToggle();
  };

  const { toggle, setToggle } = UseToggle();
  return (
    <>
      <div className="table-container overflow-auto tableLibrarySection">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="custom-table"
          hover
          bordered
        >
          <thead>
            <tr>
              <th style={{ width: 40 }} onMouseDown={(e) => onMouseDown(e, 0)}>
                Sr
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Credits</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Transaction For</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Transaction Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Transaction Date</th>
              <th style={{ width: 60 }} onMouseDown={(e) => onMouseDown(e, 5)}>
                View
              </th>
            </tr>
          </thead>
          <tbody>
            {creditTransectionData?.map((item, index) => (
              <tr key={index} className="position-relative">
                <td>{index + 1}</td>
                <td>
                  <i className="fa-solid fa-coins me-1"></i>
                  {item.credit}
                </td>
                <td>
                  {item.type === "programAI"
                    ? "Program AI"
                    : item.type === "documentAI"
                    ? "Document AI"
                    : item.type === "purchased"
                    ? "Purchased"
                    : "Reward"}
                </td>
                <td>
                  <div
                    className="text-capitalize"
                    style={{
                      color:
                        item.transactionType === "credited" ? "green" : "red",
                    }}
                  >
                    <b>{item.transactionType}</b>
                  </div>
                </td>
                <td>
                  <Moment format="DD MMM YYYY">{item.createdAt}</Moment>
                </td>

                <td>
                  <div className="text-center">
                    <button
                      className="themeButton rounded-circle p-1 px-2"
                      title="View Details in Time"
                      style={{ fontSize: 10, width: 20, height: "18.5px" }}
                      onClick={() => hanldeView(item.type, item._id)}
                      disabled={item.transactionType === "credited"}
                    >
                      <div className="hstack text-center justify-content-center">
                        <i className="fa-solid fa-eye"></i>
                      </div>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <ViewTransactionDetailsModal
        Show={!toggle}
        type={type}
        id={id}
        Hide={setToggle}
        Size={"md"}
        Title="Transaction Details"
      />
    </>
  );
};
export default CreditTransactionsTable;
