import React, { useState } from "react";
import AITableHeader from "../../AITableHeader";
import Pagination from "../../../Hooks/Pagination";
import { aiLibraryServices } from "../../../APIServices/AIAlpha/aiLibraryServices";
import Swal from "sweetalert2";
import ProgramFavoriteLibraryTable from "./ProgramFavoriteLibraryTable";

const ProgramFavoriteLibrary = ({
  id,
  setId,
  breadcrumbData,
  setBreadcrumbData,
  setActiveBreadcrumb,
  activeBreadcrumb,
  handleChangeLibraryType,
  getAILibraryData,
  setLibrary,
  library,
  cleanToolsData,
  tableRef,
  onMouseDown,
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchData, setSearchData] = useState("");

  const itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = library?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(library?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % library?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.path = "program";
    userData.searchData = searchValue || searchData;
    if (id) {
      userData.folderId = id;
    }
    let data = await aiLibraryServices.searchFavoriteLibrary(userData);
    setLibrary(data?.data);
  };

  const addNewMultiLibraryFavorite = async (e, favorite) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${favorite ? "Add" : "Remove"} this library data ${
        favorite ? "in" : "from"
      } favorite?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${favorite ? "Add" : "Remove"} it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.libraryIds = selectedIds;
        userData.favorite = favorite;
        let data = await aiLibraryServices.addMultiFavoriteAILibraryData(
          userData
        );
        if (data?.statusCode === 200) {
          if (id) {
            getAILibraryData("favoriteLibrary", id);
          } else {
            getAILibraryData("favoriteLibrary");
          }
        }
      }
    });
  };

  const handleOpenAIResultPanel = (id) => {
    setId(id);
  };

  return (
    <>
      <AITableHeader
        cleanToolsData={cleanToolsData}
        Library
        handleLibrary={(e) => handleChangeLibraryType(e, "mainLibrary")}
        Search={activeBreadcrumb?.type !== "file"}
        handleSearch={handleSearch}
        SearchReverseData={getAILibraryData}
        setSearchData={setSearchData}
        RemoveFavorite={
          selectedIds?.length > 0 && activeBreadcrumb?.type !== "file"
        }
        handleRemoveFavorite={(e) => addNewMultiLibraryFavorite(e, false)}
        setBreadcrumbData={setBreadcrumbData}
        breadcrumbData={breadcrumbData}
        activeBreadcrumb={activeBreadcrumb}
        setActiveBreadcrumb={setActiveBreadcrumb}
        setId={setId}
      />
      <div className="sidebar_Content">
        <ProgramFavoriteLibraryTable
          tableRef={tableRef}
          onMouseDown={onMouseDown}
          library={currentItems}
          getAILibraryData={getAILibraryData}
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          getAILibraryDataById={getAILibraryData}
          handleOpenAIResultPanel={handleOpenAIResultPanel}
          setBreadcrumbData={setBreadcrumbData}
          breadcrumbData={breadcrumbData}
          setActiveBreadcrumb={setActiveBreadcrumb}
          id={id}
          setId={setId}
        />
        <Pagination
          Result={library}
          itemsPerPage={itemsPerPage}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
        />
      </div>
    </>
  );
};
export default ProgramFavoriteLibrary;
