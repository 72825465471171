import React from "react";
import { Form, Table } from "react-bootstrap";
import Moment from "react-moment";
import { aiLibraryServices } from "../../../APIServices/AIAlpha/aiLibraryServices";
import Swal from "sweetalert2";

const DocumentFavoriteLibraryTable = ({
  id,
  setId,
  library,
  getAILibraryData,
  selectedIds,
  setSelectedIds,
  getAILibraryDataById,
  handleOpenAIResultPanel,
  breadcrumbData,
  setBreadcrumbData,
  setActiveBreadcrumb,
  tableRef, 
  onMouseDown,
}) => {
  const handleFavorite = async (Id, type, favorite) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${!favorite ? "Add" : "Remove"} this ${type} ${
        !favorite ? "in" : "from"
      } favorite?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${!favorite ? "Add" : "Remove"} it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = Id;
        let data = await aiLibraryServices.addFavoriteAILibraryData(userData);
        if (data?.statusCode === 200) {
          if (id) {
            getAILibraryData("favoriteLibrary", id);
          } else {
            getAILibraryData("favoriteLibrary");
          }
        }
      }
    });
  };

  const handleBredcrumbChange = (e, item) => {
    setActiveBreadcrumb(item);
    setId(item._id);
    localStorage.setItem("activeDocumentBreadcrumbData", JSON.stringify(item));
    const isDuplicate = breadcrumbData.some((data) => data._id === item._id);

    if (!isDuplicate) {
      const newBreadcrumbLists = [...breadcrumbData, item];
      setBreadcrumbData(newBreadcrumbLists);
      localStorage.setItem(
        "breadcrumbDocumentData",
        JSON.stringify(newBreadcrumbLists)
      );
    }
  };

  const handleSelectAll = () => {
    if (selectedIds?.length === library?.length) {
      setSelectedIds([]);
    } else {
      const allIds = library?.map((item) => item._id);
      setSelectedIds(allIds);
    }
  };

  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedIds.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };
  return (
    <>
      <div className="table-container overflow-auto tableLibrarySection">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="custom-table"
          hover
          bordered
        >
          <thead>
            <tr>
              <th style={{ width: 40 }} onMouseDown={(e) => onMouseDown(e, 0)}>
                <Form.Check
                  type="checkbox"
                  id="selectAll"
                  checked={
                    selectedIds?.length === 0
                      ? false
                      : selectedIds?.length === library?.length
                  }
                  onChange={handleSelectAll}
                />
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Name</th>
              <th style={{ width: 200 }} onMouseDown={(e) => onMouseDown(e, 2)}>Created Date | Time</th>
              <th style={{ width: 200 }} onMouseDown={(e) => onMouseDown(e, 3)}>Updated Date | Time</th>
              <th style={{ width: 80 }} onMouseDown={(e) => onMouseDown(e, 4)}>Bookmark</th>
            </tr>
          </thead>
          <tbody>
            {library?.map((item, index) => (
              <tr key={index} className="relative">
                <td>
                  <Form.Check
                    type="checkbox"
                    id={`checkbox${index}`}
                    checked={selectedIds?.includes(item._id)}
                    onChange={() => handleCheckboxChange(item._id)}
                  />
                </td>
                <td
                  onClick={(e) => {
                    item.type === "folder"
                      ? getAILibraryDataById("favoriteLibrary", item?._id)
                      : handleOpenAIResultPanel(item?._id);
                    handleBredcrumbChange(e, item);
                  }}
                >
                  <i
                    className={`fa-solid ${
                      item.type === "folder" ? "fa-folder me-2" : "fa-file"
                    }`}
                    style={
                      item.type === "file"
                        ? { color: "var(--accent-200)", marginRight: 12 }
                        : { color: "var(--accent-200)" }
                    }
                  ></i>
                  {item.name}
                </td>
                <td
                  onClick={(e) => {
                    item.type === "folder"
                      ? getAILibraryDataById("favoriteLibrary", item?._id)
                      : handleOpenAIResultPanel(item?._id);
                    handleBredcrumbChange(e, item);
                  }}
                >
                  <Moment format="DD MMM YYYY | HH:mm">{item.createdAt}</Moment>
                </td>
                <td
                  onClick={(e) => {
                    item.type === "folder"
                      ? getAILibraryDataById("favoriteLibrary", item?._id)
                      : handleOpenAIResultPanel(item?._id);
                    handleBredcrumbChange(e, item);
                  }}
                >
                  <Moment format="DD MMM YYYY | HH:mm">{item.updatedAt}</Moment>
                </td>
                <td>
                  <div
                    className="text-center"
                    onClick={() =>
                      handleFavorite(item._id, item.type, item.favorite)
                    }
                  >
                    <i
                      title={item.favorite ? "Remove Favorite" : "Add Favorite"}
                      className={
                        item.favorite
                          ? "fa-solid fa-star"
                          : "fa-regular fa-star"
                      }
                      style={{ color: "var(--accent-200)" }}
                    ></i>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};
export default DocumentFavoriteLibraryTable;
