import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { datasetsServices } from "../../../APIServices/AIAlpha/datasetsServices";

const AddNewStudyIdModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getProgramStudyId,
}) => {
  const [studyLabel, setStudyLabel] = useState("");

  const addNewDocumentCase = async () => {
    let userData = {};
    userData.studyLabel = studyLabel;
    let data = await datasetsServices.addNewProgramStudyId(userData);
    if (data?.statusCode === 200) {
      closeModal();
      getProgramStudyId();
    }
  };

  const closeModal = () => {
    setStudyLabel("");
    Hide();
  };
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="add_sponsor_field">
            <Form.Label>
              Study Label<span>*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              value={studyLabel}
              onChange={(e) => setStudyLabel(e.target.value)}
              placeholder="Enter Study Label"
            />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer className="py-1 px-3">
        <div className="text-end footer_button">
          <button
            className="themeButton"
            disabled={!studyLabel}
            onClick={addNewDocumentCase}
          >
            {Submit}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewStudyIdModal;
