import React, { useEffect, useState } from "react";
import { Card, Form, Modal } from "react-bootstrap";
import TrasactionChartByYear from "./TrasactionChartByYear";
import TrasactionChartByWeek from "./TrasactionChartByWeek";
import { creditsAIServices } from "../../../APIServices/AIAlpha/creditsAIServices";

const ViewAnalyticsChartModal = ({ Show, Hide, Size, Title, themeColor }) => {
  const [graphWeekData, setGraphWeekData] = useState("");
  const [graphYearData, setGraphYearData] = useState("");
  const [totalWeekCredits, setTotalWeekCredits] = useState(0);
  const [totalYearCredits, setTotalYearCredits] = useState(0);
  const [yearData, setYearData] = useState([]);

  const getCreditTransectionForWeekGraph = async () => {
    const data = await creditsAIServices.getCreditTransectionForWeekGraph();
    setGraphWeekData(data);
    const weekCreditUsage = data?.weekCreditUsage;
    if (weekCreditUsage) {
      const sum = weekCreditUsage.reduce((acc, value) => acc + value, 0);
      setTotalWeekCredits(sum);
    }
  };

  const getCreditTransectionForYearGraph = async (year) => {
    let data = await creditsAIServices.getCreditTransectionForYearGraph(year);
    setGraphYearData(data);
    const yearCreditUsage = data?.yearCreditUsage;
    if (yearCreditUsage) {
      const sum = yearCreditUsage.reduce((acc, value) => acc + value, 0);
      setTotalYearCredits(sum);
    }
  };

  const getLastFiveYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 5; i++) {
      years.push(currentYear - i);
    }
    setYearData(years);
  };

  useEffect(() => {
    getLastFiveYears();
    getCreditTransectionForWeekGraph();
    getCreditTransectionForYearGraph("2024");
  }, []);

  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div
            onClick={() => {
              Hide();
            }}
            className="close_modal"
            title="Close"
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body className="sidebar_Content">
          <div className="hstack justify-content-between mb-3">
            <div className="hstack gap-2">
              <Form.Label className="m-0">Filter By :</Form.Label>
              <div className="selectTransaction">
                <Form.Select
                  aria-label="Default select example"
                  required
                  className="py-1"
                  onChange={(e) =>
                    getCreditTransectionForYearGraph(e.target.value)
                  }
                >
                  {yearData?.map((item, index) => (
                    <option selected={item === yearData[0]} key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <Form.Label className="m-0">
              Total Used Credit : {totalYearCredits}
              <i className="fa-solid fa-coins ms-1"></i>
            </Form.Label>
          </div>
          <Card className="shadow-sm border-0">
            <Card.Body>
              <TrasactionChartByYear
                themeColor={themeColor}
                graphYearData={graphYearData}
              />
            </Card.Body>
          </Card>
          <div className="hstack justify-content-between my-3">
            <Form.Label className="m-0">Last 7 Day's</Form.Label>
            <Form.Label className="m-0">
              Total Used Credit : {totalWeekCredits}
              <i className="fa-solid fa-coins ms-1"></i>
            </Form.Label>
          </div>
          <Card className="shadow-sm border-0">
            <Card.Body>
              <TrasactionChartByWeek
                themeColor={themeColor}
                graphWeekData={graphWeekData}
              />
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewAnalyticsChartModal;
