import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import TableHeader from "../TableHeader";
import TrashTableData from "./TrashTableData";
import UseToggle from "../../../Hooks/useToggle";
import TrashExportModal from "./TrashExportModal";
import Pagination from "../../../Hooks/Pagination";
import { aiLibraryServices } from "../../../APIServices/AIAlpha/aiLibraryServices";
import TrashDeletedItemAnalysisChartModal from "./TrashDeletedItemAnalysisChartModal";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";

const Trash = ({ tableRef, onMouseDown, themeColor }) => {
  const [trashData, setTrashData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [allDayData, setAllDayData] = useState("");

  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();

  const itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = trashData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(trashData?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % trashData?.length;
    setItemOffset(newOffset);
  };

  const getDeletedAILibraryData = async () => {
    let data = await loginActivitiesServices.getDeletedAILibraryData();
    setTrashData(data?.data);
  };

  const calculateAllDeletedData = async (day) => {
    let data = await aiLibraryServices.calculateAllDeletedData(day);
    setAllDayData(data);
  };

  const handleCalculatedAllDays = () => {
    setToggle1();
    calculateAllDeletedData("all");
    // setGraphData([]);
    // setTotalCredit();
    // setTotalDeleted();
  };

  const handleSearch = async (searchData) => {
    let userData = {};
    userData.searchData = searchData || searchData;
    let data = await aiLibraryServices.searchDeletedLibrary(userData);
    setTrashData(data?.data);
  };

  const restoreAILibraryData = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to restore this file?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, restore it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await aiLibraryServices.restoreAILibraryData(id);
        if (data?.statusCode === 200) {
          getDeletedAILibraryData();
        }
      }
    });
  };

  const permanetDeletedAILibraryData = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this file?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await aiLibraryServices.permanetDeletedAILibraryData(id);
        if (data?.statusCode === 200) {
          getDeletedAILibraryData();
        }
      }
    });
  };

  const restoreMultiAILibraryData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Restore All the files?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Restore it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.ids = selectedIds;
        let data = await aiLibraryServices.restoreMultiAILibraryData(userData);
        if (data?.statusCode === 200) {
          getDeletedAILibraryData();
          setSelectedIds([]);
        }
      }
    });
  };

  const permanetDeletedMultiAILibraryData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Permanent delete the files?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.ids = selectedIds;
        let data = await aiLibraryServices.permanetDeletedMultiAILibraryData(
          userData
        );
        if (data?.statusCode === 200) {
          getDeletedAILibraryData();
          setSelectedIds([]);
        }
      }
    });
  };

  useEffect(() => {
    getDeletedAILibraryData();
  }, []);
  return (
    <>
      <TableHeader
        Search
        searchData={searchData}
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        SearchReverseData={getDeletedAILibraryData}
        Restore={selectedIds?.length > 0}
        handleRestore={restoreMultiAILibraryData}
        titleRestore={"Restore"}
        Delete={selectedIds?.length > 0}
        handleDelete={permanetDeletedMultiAILibraryData}
        titleDelete={"Delete"}
        ViewAnalylics
        handleViewAnalyics={handleCalculatedAllDays}
        titleViewAnalyics={"Deleted Items Analytics"}
        Export
        handleExport={setToggle}
        titleExport={"Export Trash Data"}
      />
      <div className="sidebar_Content">
        <TrashTableData
          trashData={currentItems}
          onMouseDown={onMouseDown}
          tableRef={tableRef}
          restoreAILibraryData={restoreAILibraryData}
          permanetDeletedAILibraryData={permanetDeletedAILibraryData}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
        <Pagination
          Result={trashData}
          itemsPerPage={itemsPerPage}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
        />
      </div>
      <TrashExportModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title="Export Trash Data"
        Submit={"Export"}
      />
      <TrashDeletedItemAnalysisChartModal
        Show={!toggle1}
        themeColor={themeColor}
        Hide={handleCalculatedAllDays}
        calculateAllDeletedData={calculateAllDeletedData}
        allDayData={allDayData}
        Size={"lg"}
        Title="Deleted Items Analytics"
      />
    </>
  );
};

export default Trash;
