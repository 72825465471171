import React, { Suspense, useEffect, useRef, useState } from "react";
import "./App.css";
import "./Responsive.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import DocumentAICreate from "./Component/Document/DocumentAICreate";
import ProgramAICreate from "./Component/Program/ProgramAICreate";
import AllRoutesData from "./Routes";
import {
  ForgetPassword,
  Login,
  OtpVerification,
  ResetPassword,
  SignUp,
} from "./Component/Auth";
import { Permission } from "./ProtectRoute/PermissionRoutes";
import NotFoundPage from "./Component/NotFoundPage";
import Loading from "./Loading/Loading";
import { authServices } from "./APIServices/authServices";
import { themeSettingsServices } from "./APIServices/themeSettingsServices";
import InvitationForm from "./Component/Auth/Invitation/InvitationForm";
import { PaymentCancel, PaymentSuccess } from "./Component/Account";
import InvoiceReceipt from "./Component/Account/Billing/InvoiceReceipt";

function App() {
  const { LoginPermission, DashboardPermission } = Permission;

  const [twoFA, setTwoFA] = useState(false);
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [email, setEmail] = useState("");
  const [authType, setAuthType] = useState("");
  const [userCurrrentData, setUserCurrrentData] = useState("");
  const [themeColor, setThemeColor] = useState("");
  const [font, setFont] = useState("");

  let currentUserData = async () => {
    let result = await authServices.getCurrectLoginUser();
    let data = result?.currentData;
    setEmail(data?.email);
    setTwoFA(data?.twoFA);
    setPasswordUpdate(data?.isUpdatePassword);
    setAuthType(data?.authType);
    setUserCurrrentData(data);
  };

  const getThemeColor = async () => {
    let data = await themeSettingsServices.getThemeColor();
    setThemeColor(data?.rgbColor);
  };

  const getThemeFont = async () => {
    let data = await themeSettingsServices.getThemeFont();
    setFont(data?.fontName);
  };

  document.documentElement.style.setProperty(
    "--accent-200",
    `rgb(${themeColor ? themeColor : "0 97 154"} / 5%)`
  );
  document.documentElement.style.setProperty(
    "--bg-300",
    `rgb(${themeColor ? themeColor : "0 97 154"} / 10%)`
  );
  document.documentElement.style.setProperty(
    "--accent-200",
    `rgb(${themeColor ? themeColor : "0 97 154"} / 100%)`
  );
  document.documentElement.style.setProperty("--font_family", font);

  useEffect(() => {
    getThemeColor();
    getThemeFont();
    currentUserData();
  }, []);

  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const th = tableRef.current.querySelector(
          `th:nth-child(${resizingColumn + 1})`
        );
        th.style.width = `${width}px`;
        tableRef.current.classList.add("resizing");
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    tableRef.current.querySelectorAll("th").forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="*" element={<NotFoundPage />} />

            <Route path="/" element={<LoginPermission />}>
              <Route path="/" element={<Login />} />
            </Route>

            <Route path="/login" element={<LoginPermission />}>
              <Route path="/login" element={<Login />} />
            </Route>

            <Route path="/sign-up" element={<LoginPermission />}>
              <Route path="/sign-up" element={<SignUp />} />
            </Route>

            <Route path="/otp-verify" element={<LoginPermission />}>
              <Route path="/otp-verify" element={<OtpVerification />} />
            </Route>

            <Route path="/forget-password" element={<LoginPermission />}>
              <Route path="/forget-password" element={<ForgetPassword />} />
            </Route>

            <Route path="/resetPassword" element={<LoginPermission />}>
              <Route path="/resetPassword" element={<ResetPassword />} />
            </Route>

            <Route path="/invitation/:token" element={<LoginPermission />}>
              <Route path="/invitation/:token" element={<InvitationForm />} />
            </Route>

            <Route
              path="*"
              element={
                <DashboardPermission
                  twoFA={twoFA}
                  authType={authType}
                  email={email}
                  passwordUpdate={passwordUpdate}
                  currentUserData={currentUserData}
                />
              }
            >
              <Route path="*" element={<NotFoundPage />} />
            </Route>

            <Route
              path="/home"
              element={
                <DashboardPermission
                  twoFA={twoFA}
                  authType={authType}
                  email={email}
                  passwordUpdate={passwordUpdate}
                  currentUserData={currentUserData}
                />
              }
            >
              <Route
                path="/home"
                element={<HomePage userCurrrentData={userCurrrentData} />}
              />
            </Route>

            <Route
              path="/document-ai"
              element={
                <DashboardPermission
                  twoFA={twoFA}
                  authType={authType}
                  email={email}
                  passwordUpdate={passwordUpdate}
                  currentUserData={currentUserData}
                />
              }
            >
              <Route
                path="/document-ai"
                element={
                  <DocumentAICreate
                    userCurrrentData={userCurrrentData}
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                }
              />
            </Route>

            <Route
              path="/program-ai"
              element={
                <DashboardPermission
                  twoFA={twoFA}
                  authType={authType}
                  email={email}
                  passwordUpdate={passwordUpdate}
                  currentUserData={currentUserData}
                />
              }
            >
              <Route
                path="/program-ai"
                element={
                  <ProgramAICreate userCurrrentData={userCurrrentData}  tableRef={tableRef} onMouseDown={onMouseDown}/>
                }
              />
            </Route>

            <Route
              path="/account/:permalink"
              element={
                <DashboardPermission
                  twoFA={twoFA}
                  authType={authType}
                  email={email}
                  passwordUpdate={passwordUpdate}
                  currentUserData={currentUserData}
                />
              }
            >
              <Route
                path="/account/:permalink"
                element={
                  <AllRoutesData
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                    themeColor={themeColor}
                    userCurrrentData={userCurrrentData}
                    currentUserData={currentUserData}
                    getThemeColor={getThemeColor}
                    getThemeFont={getThemeFont}
                    font={font}
                  />
                }
              />
            </Route>

            <Route
              path="/payment-success/:uniqueId"
              element={
                <DashboardPermission
                  twoFA={twoFA}
                  authType={authType}
                  email={email}
                  passwordUpdate={passwordUpdate}
                  currentUserData={currentUserData}
                />
              }
            >
              <Route
                path="/payment-success/:uniqueId"
                element={<PaymentSuccess userCurrrentData={userCurrrentData} />}
              />
            </Route>

            <Route
              path="/payment-cancel/:uniqueId"
              element={
                <DashboardPermission
                  twoFA={twoFA}
                  authType={authType}
                  email={email}
                  passwordUpdate={passwordUpdate}
                  currentUserData={currentUserData}
                />
              }
            >
              <Route
                path="/payment-cancel/:uniqueId"
                element={<PaymentCancel userCurrrentData={userCurrrentData} />}
              />
            </Route>

            <Route
              path="/invoiceReceipt/:transactionId"
              element={
                <DashboardPermission
                  twoFA={twoFA}
                  authType={authType}
                  email={email}
                  passwordUpdate={passwordUpdate}
                  currentUserData={currentUserData}
                />
              }
            >
              <Route
                path="/invoiceReceipt/:transactionId"
                element={
                  <InvoiceReceipt
                    userCurrrentData={userCurrrentData}
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
