import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const LogTableData = ({ logData, tableRef, onMouseDown }) => {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "login":
        return "green";
      case "logout":
        return "red";
      default:
        return "inherit";
    }
  };
  return (
    <>
      <div className="table-container overflow-auto tableLibrarySection">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="custom-table"
          hover
          bordered
        >
          <thead>
            <tr>
              <th style={{ width: 40 }} onMouseDown={(e) => onMouseDown(e, 0)}>
                Sr
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>IP Address</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Time Zone</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Location</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Message</th>
              <th style={{ width: 100 }} onMouseDown={(e) => onMouseDown(e, 5)}>
                Status
              </th>
              <th style={{ width: 150 }} onMouseDown={(e) => onMouseDown(e, 6)}>
                IN Out Time
              </th>
            </tr>
          </thead>
          <tbody>
            {logData?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.ipAddress}</td>
                <td>{item.timeZone}</td>
                <td>{item.location}</td>
                <td>{item.activities}</td>
                <td>
                  <div style={{ color: getStatusColor(item.status) }}>
                    <b> {item.status}</b>
                  </div>
                </td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">{item.createdAt}</Moment>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default LogTableData;
