import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { authServices } from "../../../APIServices/authServices";

const PasswordModal = ({ Show, Hide, Size, Title, Submit }) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword((prevState) => !prevState);
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const changePassword = async () => {
    let userData = {};
    userData.currentPassword = currentPassword;
    userData.newPassword = newPassword;
    userData.repeatPassword = repeatPassword;
    let data = await authServices.changePassword(userData);
    if (data?.statusCode === 200) {
      closeModal();
    }
  };

  const closeModal = () => {
    Hide();
    resetInputField();
  };

  const [error, setError] = useState("");

  const validatePassword = (input) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;

    if (!passwordRegex.test(input)) {
      setError(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
      );
    } else {
      setError("");
    }
  };

  const resetInputField = () => {
    setCurrentPassword("");
    setNewPassword("");
    setRepeatPassword("");
  };

  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="mb-3">
            <Form.Label>
              Current Password <span>*</span>{" "}
            </Form.Label>
            <div className="position-relative">
              <Form.Control
                required
                type="password"
                placeholder="Enter Old Password"
                onChange={(e) => setCurrentPassword(e.target.value)}
                value={currentPassword}
              />
              <div
                className="passwordvisiability"
                onClick={toggleCurrentPasswordVisibility}
              >
                {showCurrentPassword ? (
                  <i className="fa-regular fa-eye-slash"></i>
                ) : (
                  <i className="fa-regular fa-eye"></i>
                )}
              </div>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-3">
            <Form.Label>
              New Password <span>*</span>
            </Form.Label>
            <div className="position-relative">
              <Form.Control
                required
                type={showPassword ? "text" : "password"}
                placeholder="Enter New Password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  validatePassword(e.target.value);
                }}
                value={newPassword}
              />
              {!newPassword ? (
                ""
              ) : error ? (
                <span style={{ fontSize: 10, color: "red", fontWeight: 600 }}>
                  Password Must have 1 Capital Letter, 1 Small Letter, 1 Special
                  Character with 8 Character
                </span>
              ) : (
                <span style={{ fontSize: 10, color: "green", fontWeight: 600 }}>
                  Strong Password
                </span>
              )}
              <div
                className="passwordvisiability"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <i className="fa-regular fa-eye-slash"></i>
                ) : (
                  <i className="fa-regular fa-eye"></i>
                )}
              </div>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-3">
            <Form.Label>
              Confirm Password <span>*</span>
            </Form.Label>
            <div className="position-relative">
              <Form.Control
                required
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Enter Password"
                onChange={(e) => setRepeatPassword(e.target.value)}
                value={repeatPassword}
              />
              <div
                className="passwordvisiability"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? (
                  <i className="fa-regular fa-eye-slash"></i>
                ) : (
                  <i className="fa-regular fa-eye"></i>
                )}
              </div>
              {newPassword === repeatPassword ? (
                newPassword?.length === 0 ? (
                  ""
                ) : (
                  <span
                    style={{ fontSize: 10, color: "green", fontWeight: 600 }}
                  >
                    Password Match
                  </span>
                )
              ) : (
                <span style={{ fontSize: 10, color: "red", fontWeight: 600 }}>
                  Password Not Match
                </span>
              )}
            </div>
          </Form.Group>
        </Row>
        <div className="hstack gap-3 justify-content-end border-top pt-3">
          <button onClick={resetInputField} className="themeButton">
            Reset
          </button>
          <button
            className="themeButton"
            disabled={!currentPassword || !newPassword || !repeatPassword}
            onClick={changePassword}
          >
            {Submit}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordModal;
