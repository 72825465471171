import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { programAIServices } from "../../APIServices/AIAlpha/programAIServices";
import ViewDatasetColumnsModal from "./ViewDatasetColumnsModal";
import UseToggle from "../../Hooks/useToggle";
import { datasetsServices } from "../../APIServices/AIAlpha/datasetsServices";
import { handleResponse } from "../../APIHelpers/Responses";
import { creditsAIServices } from "../../APIServices/AIAlpha/creditsAIServices";

const ProgramAITools = ({
  activeBreadcrumb,
  setInputText,
  inputText,
  setLanguages,
  languages,
  setType,
  type,
  generateAIData,
  selectedDatasetList,
  setSelectedDatasetList,
  allDatasetList,
  setAllDatasetList,
  setSteps,
  steps,
  setPromptList,
  promptList,
  datasetList,
  setDatasetList,
  loading
}) => {
  const { toggle, setToggle } = UseToggle();
  const [languageList, setLanguageList] = useState([]);
  const [studyIdList, setStudyIdList] = useState([]);
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [viewColumnList, setViewColumnList] = useState([]);

  const getActiveProgramLanguage = async () => {
    let data = await programAIServices.getActiveProgramLanguage();
    setLanguageList(data?.data);
  };

  const getActiveProgramPrompts = async (programId) => {
    let data = await programAIServices.getActiveProgramPrompts(programId);
    setPromptList(data?.data);
  };

  const getProgramStudyId = async () => {
    let data = await datasetsServices.getProgramStudyId();
    setStudyIdList(data?.data);
  };

  const getDataSetsByProgramStudyId = async (studyId) => {
    let data = await datasetsServices.getDataSetsByProgramStudyId(studyId);
    let result = data?.data
    let datasets = result?.datasets
    let finalResults = [...new Set(datasets?.map(item => item.Datasets))];
    setDatasetList(finalResults);
    setAllDatasetList(datasets)
  };

  const selectDatasets = (dataset) => {
    if (selectedDatasetList?.length === 0) {
      setSelectedDatasetList([dataset]);
    } else {
      setSelectedDatasetList([...new Set([...selectedDatasetList, dataset])]);
    }
  }

  const removeDatasets = (dataset) => {
    let data = selectedDatasetList?.filter(item => item !== dataset);
    setSelectedDatasetList(data)
  }

  const getTotalRemainingCredit = async () => {
    const data = await creditsAIServices.getTotalRemainingCredit();
    setRemainingCredits(data?.remainingCredits);
  };

  const viewDatasetColumnInfo = (dataset) => {
    let data = allDatasetList?.filter(item => item.Datasets === dataset)
    setViewColumnList(data)
    setToggle()
  }

  const creditNotAvailable = async () => {
    let Message = {
      statusCode: 400,
      customMessage: "Opps! You have insufficient Credits. Please Recharge First.",
    }
    handleResponse(Message);
  };

  useEffect(() => {
    getTotalRemainingCredit();
    getActiveProgramLanguage();
    getProgramStudyId()
  }, []);

  return (
    <>
      <div className="position-relative">
        <div
          className="sidebar_Content"
          style={{ height: "calc(100vh - 178px)" }}
        >
          <Container fluid className="pt-2 total_data_program">
            <Row>
              <Form.Group as={Col} lg={promptList?.length === 0 ? "12" : "6"} md={12} sm={12} className="mb-2">
                <Form.Label>Select Language</Form.Label>
                <Form.Select
                  onChange={(e) => setLanguages(e.target.value)}
                  onClick={(e) =>
                    getActiveProgramPrompts(
                      e.target[e.target.selectedIndex].title
                    )
                  }
                  disabled={activeBreadcrumb?.type !== "file" || loading}
                  aria-label="Default select example"
                >
                  <option selected>Select Language</option>
                  {languageList?.map((item, index) => (
                    <option key={index} title={item._id} value={item.languages}>
                      {item.languages}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {promptList?.length > 1 &&
                <Form.Group as={Col} lg="6" md={12} sm={12} className="mb-2">
                  <Form.Label>Select Type</Form.Label>
                  <Form.Select
                    onChange={(e) => setType(e.target.value)}
                    onClick={(e) =>
                      setSteps(
                        e.target[e.target.selectedIndex].title
                      )
                    }
                    disabled={activeBreadcrumb?.type !== "file" || loading}
                    aria-label="Default select example"
                  >
                    <option selected>Select Type</option>
                    {promptList?.map((item, index) => (
                      <option key={index} title={item.steps} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              }
              <Form.Group as={Col} lg={datasetList?.length === 0 ? "12" : "6"} md={12} sm={12} className="mb-2">
                <Form.Label>Select Study Id</Form.Label>
                <Form.Select
                  disabled={activeBreadcrumb?.type !== "file" || loading}
                  onClick={(e) =>
                    getDataSetsByProgramStudyId(
                      e.target.value
                    )
                  }
                  aria-label="Default select example"
                >
                  <option selected>Select Study</option>
                  {studyIdList?.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.studyLabel}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {datasetList?.length > 0 &&
                <Form.Group as={Col} lg="6" md={12} sm={12}>
                  <Form.Label>Datasets</Form.Label>
                  <Form.Select
                    disabled={activeBreadcrumb?.type !== "file" || loading}
                    aria-label="Default select example"
                    onChange={(e) => selectDatasets(e.target.value)}
                  >
                    <option value={null}>Select Datasets</option>
                    {datasetList?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              }
              {selectedDatasetList?.length > 0 &&
                <Form.Group as={Col} md="12">
                  <Form.Text className="m-0">
                    <b>Datasets List</b>
                  </Form.Text>
                  <div className="badgesChips">
                    {selectedDatasetList?.map((item, index) => (
                      <div key={index} className="chip hstack mt-1">
                        <span>
                          <b>{item}</b>
                        </span>
                        <div className="vr"></div>
                        <i
                          onClick={() => viewDatasetColumnInfo(item)}
                          className="fa-solid fa-circle-info"
                          title="Info"
                        ></i>
                        <div className="vr"></div>
                        <i
                          onClick={() => removeDatasets(item)}
                          className="fa-regular fa-circle-xmark"
                          style={{ color: "red" }}
                          title="Delete"
                        ></i>
                      </div>
                    ))}
                  </div>
                </Form.Group>
              }
              <Form.Group as={Col} md="12" className="mt-3">
                <Form.Control
                  onChange={(e) => setInputText(e.target.value)}
                  value={inputText}
                  disabled={activeBreadcrumb?.type !== "file" || loading}
                  className="mb-2"
                  as="textarea"
                  placeholder="Wirte your program here"
                />
              </Form.Group>
            </Row>
          </Container>
        </div>
        <div className="fixRightButtoon">
          <Form.Group as={Col} md="12">
            {remainingCredits === 0 ?
              <button
                onClick={creditNotAvailable}
                disabled={activeBreadcrumb?.type !== "file" || selectedDatasetList?.length === 0 || !inputText || !type || !languages || steps?.length === 0}
                className="themeButton"
              >
                Write for me
              </button>
              :
              <button
                onClick={generateAIData}
                disabled={activeBreadcrumb?.type !== "file" || selectedDatasetList?.length === 0 || !inputText || !type || !languages || steps?.length === 0}
                className="themeButton"
              >
                Write for me
                <Spinner
                  as="span"
                  animation={loading && "border"}
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </button>
            }
          </Form.Group>
        </div>
      </div>
      <ViewDatasetColumnsModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title="Dataset Columns"
        viewColumnList={viewColumnList}
      />
    </>
  );
};

export default ProgramAITools;
