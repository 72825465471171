import React, { useEffect, useState } from "react";
import TableHeader from "../TableHeader";
import UseToggle from "../../../Hooks/useToggle";
import ExportCreditTransactionsModal from "./ExportCreditTransactionsModal";
import ViewAnalyticsChartModal from "./ViewAnalyticsChartModal";
import { creditsAIServices } from "../../../APIServices/AIAlpha/creditsAIServices";
import CreditTransactionsTable from "./CreditTransactionsTable";
import Pagination from "../../../Hooks/Pagination";

const CreditTransactions = ({ themeColor, tableRef, onMouseDown }) => {
  const { toggle1, setToggle1, toggle2, setToggle2 } = UseToggle();
  const [creditTransectionData, setCreditTransectionData] = useState([]);

  const getcreditTransectionData = async (type) => {
    let data = await creditsAIServices.getCreditTransectionByDate(type);
    setCreditTransectionData(data?.data);
  };

  const itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = creditTransectionData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(creditTransectionData?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % creditTransectionData?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    getcreditTransectionData("all");
  }, []);

  return (
    <>
      <TableHeader
        SelectType
        handleSelectType={(e) => getcreditTransectionData(e.target.value)}
        ViewAnalylics
        handleViewAnalyics={setToggle2}
        titleViewAnalyics={"View Analytics"}
        Export
        handleExport={setToggle1}
        titleExport={"Export Credit Transactions Data"}
      />
      <div className="sidebar_Content">
        <CreditTransactionsTable creditTransectionData={currentItems} onMouseDown={onMouseDown} tableRef={tableRef}/>
        <Pagination
          itemsPerPage={itemsPerPage}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
        />
      </div>
      <ViewAnalyticsChartModal
        Show={!toggle2}
        Hide={setToggle2}
        Size={"lg"}
        Title="View Credit Analytics"
        themeColor={themeColor}
      />
      <ExportCreditTransactionsModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title="Export Transections Data"
        Submit={"Export"}
      />
    </>
  );
};
export default CreditTransactions;
