import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LoginImage from "../../image/LoginPage.gif";
import { authServices } from "../../APIServices/authServices";

const OtpVerification = ({ authType, email, currentUserData }) => {
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const otpInputs = useRef([]);

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]$/.test(value)) {
      const updatedValues = [...otpValues];
      updatedValues[index] = value;
      setOtpValues(updatedValues);
      if (value !== "" && index < 5) {
        otpInputs.current[index + 1].focus();
      }
      if (value === "" && index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };

  const twpFactorAuthenticationVerify = async () => {
    let endPoint =
      authType === "apps"
        ? authServices.verifyTwoFAForAppLogin
        : authServices.verifyTwoFAForEmailLogin;
    let userData = {};
    userData.otp = otpValues;
    let data = await endPoint(userData);
    if (data?.statusCode === 200) {
      setOtpValues(["", "", "", "", "", ""]);
      currentUserData();
    }
  };

  const logout = async () => {
    await authServices.logout();
  };

  const [timer, setTimer] = useState(120);
  const [display, setDisplay] = useState("02:00");

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer > 0) {
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        const displayValue = `${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;
        setDisplay(displayValue);
        setTimer((timer) => timer - 1);
      } else {
        setDisplay("00:00");
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  const sendOTPForEmailTwoFa = async () => {
    await authServices.emailOTPFor2FA();
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="p-0" lg={7} sm={12} md={12}>
            <div className="LoginImg">
              <img src={LoginImage} alt="LoginPic" />
            </div>
          </Col>
          <Col className="p-0 login_page" lg={5} sm={12} md={12}>
            <Card className="shadow-sm border-0">
              <Card.Body>
                <section className="sign-up">
                  <h5 className="text-center">
                    <b>Otp Verification</b>
                  </h5>
                  <Row>
                    <Col md={8} className="m-auto">
                      <div className="otp-input-fields">
                        {otpValues.map((value, index) => (
                          <input
                            key={index}
                            type="text"
                            pattern="\d*"
                            maxLength="1"
                            className={`otp__digit otp__field__${index + 1}`}
                            value={value}
                            onChange={(e) => handleInputChange(index, e)}
                            ref={(input) => (otpInputs.current[index] = input)}
                          />
                        ))}
                      </div>
                      <button
                        className="mb-3 themeButton w-100"
                        onClick={twpFactorAuthenticationVerify}
                        type="submit"
                      >
                        SUbmit
                      </button>
                      <div className="d-flex justify-content-between">
                        <p
                          className="message"
                          style={{ color: "var(--red_color)" }}
                        >
                          Wrong Otp
                        </p>
                        <div
                          className={
                            authType === "email"
                              ? "d-flex justify-content-between"
                              : "d-flex justify-content-end"
                          }
                        >
                          <p className="message">
                            <NavLink onClick={logout}>Logout</NavLink>
                          </p>
                          {authType === "email" && (
                            <p
                              className="message"
                              title="Clich here for generate new OTP"
                            >
                              {display === "00:00" ? (
                                <NavLink onClick={sendOTPForEmailTwoFa}>
                                  Resend OTP
                                </NavLink>
                              ) : (
                                <NavLink>{display}</NavLink>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </section>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default OtpVerification;
