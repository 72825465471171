import React, { useState } from "react";
import { authServices } from "../../../APIServices/authServices";
import { Form, Modal, Row } from "react-bootstrap";
import TwoFactorAuthByApps from "./TwoFactorAuthByApps";
import TwoFactorAuthByEmail from "./TwoFactorAuthByEmail";

const SecurityModal = ({ Show, Hide, Size, Title, setAuthType, authType, authTypeData, status, appTwoFA, emailTwoFA, getTwoFactorAuthType, roleType }) => {

  const [nextBy, setNextBy] = useState(false)
  const [modalSize, setModalSize] = useState(false)

  const setAuthenticationType = async () => {
    let userData = {}
    userData.authType = authType
    let data = await authServices.twoFactorAuthType(userData);
    if (data?.statusCode === 200) {
      getTwoFactorAuthType();
      setNextBy(true)
    }
  }

  const hideModalData = () => {
    Hide()
    setNextBy(false)
    setModalSize(false)
  }

  let twoFAData = emailTwoFA || appTwoFA
  return (
    <Modal
      show={Show}
      onHide={hideModalData}
      size={!modalSize ? Size : "sm"}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={hideModalData} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          {!nextBy && !twoFAData ?
            <Row>
              <Form.Group className="add_sponsor_field mb-3">
                <Form.Label>
                  Authentication Type <span>*</span>
                </Form.Label>
                <Form.Select
                  disabled={roleType === "subadmin" || roleType === "user" || status}
                  onChange={(e) => setAuthType(e.target.value)}
                  aria-label="Default select example"
                >
                  <option>Select Authentication Type</option>
                  <option selected={authTypeData === "email"} value="email">By Email</option>
                  <option selected={authTypeData === "apps"} value="apps">By Apps</option>
                </Form.Select>
              </Form.Group>
              <div className="hstack gap-3 justify-content-end pt-1">
                {roleType === "subadmin" || roleType === "user" ?
                  <button className="themeButton" onClick={() => setNextBy(true)}>Next</button>
                  :
                  <button className="themeButton" onClick={() => setAuthenticationType()}>Next</button>
                }
              </div>
            </Row>
            :
            <>
              {authTypeData === "apps" ?
                <TwoFactorAuthByApps
                  setNextBy={setNextBy}
                  setModalSize={setModalSize}
                  appTwoFA={appTwoFA}
                  getTwoFactorAuthType={getTwoFactorAuthType}
                />
                : authTypeData === "email" ?
                  <TwoFactorAuthByEmail
                    setNextBy={setNextBy}
                    setModalSize={setModalSize}
                    emailTwoFA={emailTwoFA}
                    getTwoFactorAuthType={getTwoFactorAuthType}
                  />
                  : ""
              }
            </>
          }
        </>
      </Modal.Body>
    </Modal>
  );
};

export default SecurityModal;
