import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import MainHeader from "../Component/MainHeader";
import Footer from "../Component/Footer";

const HomePage = ({ userCurrrentData }) => {
  return (
    <>
      <MainHeader userCurrrentData={userCurrrentData} />
      <div className="home_page_section admin-main-content-area">
        <section className="wrapper">
          <Container>
            <Row>
              <Col sm={12} md={6} lg={4} className="mb-4">
                <Card className="card-has-bg documentAi shadow">
                  <div className="card-img-overlay d-flex flex-column">
                    <Card.Body className="card-body">
                      <h5 className="card-title mt-0 ">Document AI</h5>
                      <small
                        className="mb-2"
                        style={{
                          color: "var(--bg-100)",
                          fontSize: "var(--font-size-regular)",
                        }}
                      >
                        Crafting high-quality study documents that adhere to
                        industry standards can be a complex and time-consuming
                        task. However, with the help of AI writing assistants,
                        this process can be streamlined and optimized. Our
                        advanced AI technology can generate study documents,
                        such as Statistical Analysis Plans (SAPs), Study
                        Protocols, and Clinical Study Reports (CSRs), that
                        seamlessly align with your organization's specific
                        requirements and formatting guidelines.
                      </small>
                    </Card.Body>
                    <Card.Footer className="px-0">
                      <div className="media">
                        <div className="media-body">
                          <NavLink to={"/document-ai"}>
                            <button className="themeButton">Get Started</button>
                          </NavLink>
                        </div>
                      </div>
                    </Card.Footer>
                  </div>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={4} className="mb-4">
                <Card className="card-has-bg programAi shadow">
                  <div className="card-img-overlay d-flex flex-column">
                    <Card.Body className="card-body">
                      <h5 className="card-title mt-0 ">Program AI</h5>
                      <small
                        className="mb-2"
                        style={{
                          color: "var(--bg-100)",
                          fontSize: "var(--font-size-regular)",
                        }}
                      >
                        With the latest advancements in artificial intelligence,
                        you can now create programs like SAS, GAS, R, and Python
                        with unprecedented ease and speed. Say goodbye to the
                        tedious hours spent debugging and optimizing your code.
                        AI-driven programming puts the power in your hands,
                        allowing you to save valuable time and reduce costs
                        significantly.
                      </small>
                    </Card.Body>
                    <Card.Footer className="px-0">
                      <div className="media">
                        <div className="media-body">
                          <NavLink to={"/program-ai"}>
                            <button className="themeButton">Get Started</button>
                          </NavLink>
                        </div>
                      </div>
                    </Card.Footer>
                  </div>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={4} className="mb-4">
                <Card className="card-has-bg Analytics shadow">
                  <div className="card-img-overlay d-flex flex-column">
                    <Card.Body className="card-body">
                      <h5 className="card-title mt-0 ">Make Your Analytics</h5>
                      <small
                        className="mb-2"
                        style={{
                          color: "var(--bg-100)",
                          fontSize: "var(--font-size-regular)",
                        }}
                      >
                        With just a few clicks, you can upload your Statistical
                        Analysis Plan (SAP) and let our advanced algorithms
                        handle the heavy lifting. Designed to analyze both
                        primary and secondary endpoints with unparalleled
                        precision and speed. Say goodbye to manual data
                        crunching and hello to data-driven decision making.
                      </small>
                    </Card.Body>
                    <Card.Footer className="px-0">
                      <div className="media">
                        <div className="media-body">
                          <button className="themeButton">Comming Soon</button>
                        </div>
                      </div>
                    </Card.Footer>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
