import React, { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LoginImage from "../../image/LoginPage.gif";
import { authServices } from "../../APIServices/authServices";

const ForgetPassword = () => {
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const forgotPassword = async () => {
    let userData = {};
    userData.email = email;
    let data = await authServices.forgotPassword(userData);
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      window.location.href = "/login";
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
    } else {
      setEmailError("Invalid Email, Please Enter Valid Email!");
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="p-0" lg={7} sm={12} md={12}>
            <div className="LoginImg">
              <img src={LoginImage} alt="LoginPic" />
            </div>
          </Col>
          <Col className="p-0 login_page" lg={5} sm={12} md={12}>
            <Card className="shadow-sm border-0">
              <Card.Body>
                <section className="sign-up">
                  <h5 className="text-center">
                    <b>Forget Password</b>
                  </h5>
                  <Row>
                    <Form.Group as={Col} md="12" className="mb-3">
                      <Form.Label>
                        Email <span>*</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          setEmail(e.target.value);
                          validateEmail(e.target.value);
                        }}
                        value={email}
                        required
                        type="email"
                        placeholder="Enter Email ID"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-3">
                      <button
                        onClick={forgotPassword}
                        className="mb-3 themeButton w-100"
                        type="submit"
                        disabled={!email || emailError}
                      >
                        Send
                      </button>
                      <div className="d-flex justify-content-between">
                        {emailError ? (
                          <p className="message m-0" style={{ color: "red" }}>
                            * {emailError}
                          </p>
                        ) : (
                          <p
                            className="message"
                            style={
                              statusMessage?.statusCode === 200
                                ? { color: "green" }
                                : { color: "red" }
                            }
                          >
                            {statusMessage?.customMessage}
                          </p>
                        )}
                        <p className="message">
                          <NavLink to="/login">
                            <b>Back to Login</b>
                          </NavLink>
                        </p>
                      </div>
                    </Form.Group>
                  </Row>
                </section>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ForgetPassword;
