import React from "react";
import AIDocumentComponent from "./AIDocument/AIDocumentComponent";
import AIProgramComponent from "./AIProgram/AIProgramComponent";

const Dashboard = ({ themeColor }) => {
  return (
    <>
      <div className="admin-main-content-area">
        <AIDocumentComponent themeColor={themeColor} />
        <AIProgramComponent themeColor={themeColor} />
      </div>
    </>
  );
};
export default Dashboard;
