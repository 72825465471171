import React from "react";
import { Modal, Table } from "react-bootstrap";

const ViewDatasetColumnsModal = ({
  Show,
  Hide,
  Size,
  Title,
  viewColumnList,
}) => {
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          {viewColumnList?.[0]?.Datasets} {Title}
        </Modal.Title>
        <div onClick={Hide} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          className="overflow-auto"
          style={{ maxHeight: "calc(100vh - 137px)" }}
        >
          <Table>
            <thead>
              <tr>
                <th style={{ width: 40 }}>Sr.</th>
                <th>Variables</th>
                <th>Labels</th>
              </tr>
            </thead>
            <tbody>
              {viewColumnList?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.Variables}</td>
                  <td>{item.Labels}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewDatasetColumnsModal;
