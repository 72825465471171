import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AddNewStudyIdModal from "./AddNewStudyIdModal";
import { datasetsServices } from "../../../APIServices/AIAlpha/datasetsServices";
import StudyIdTableData from "./StudyIdTableData";
import DatasetsTableData from "./DatasetDatas/DatasetsTableData";
import UseToggle from "../../../Hooks/useToggle";
import Pagination from "../../../Hooks/Pagination";
import TableHeader from "../TableHeader";

const Datasets = ({tableRef, onMouseDown }) => {
  const { toggle, setToggle } = UseToggle();
  const [viewDatasets, setViewDatasets] = useState("");
  const [studyId, setStudyId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [studyListList, setStudyList] = useState([]);
  const [datasetList, setDatasetList] = useState([]);
  const getProgramStudyId = async () => {
    let data = await datasetsServices.getProgramStudyId();
    setStudyList(data?.data);
  };

  const getDataSetsByProgramStudyId = async (studyId) => {
    setStudyId(studyId);
    let data = await datasetsServices.getDataSetsByProgramStudyId(studyId);
    setDatasetList(data?.data?.datasets);
  };

  const itemsPerPageDocumentCase = 16;
  const [itemOffsetStudy, setItemOffsetStudy] = useState(0);
  const endOffsetDocumentCase = itemOffsetStudy + itemsPerPageDocumentCase;
  const currentItemsDocumentCase = studyListList?.slice(
    itemOffsetStudy,
    endOffsetDocumentCase
  );
  const pageCountDocumentCase = Math.ceil(
    studyListList?.length / itemsPerPageDocumentCase
  );
  const handlePageClickStudy = (event) => {
    const newOffset =
      (event.selected * itemsPerPageDocumentCase) % studyListList?.length;
    setItemOffsetStudy(newOffset);
  };

  const itemsPerPage = 16;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = datasetList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(datasetList?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % datasetList?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await datasetsServices.searchProgramStudyId(userData);
    setStudyList(data?.data);
  };

  useEffect(() => {
    getProgramStudyId();
  }, []);

  return (
    <>
      <Row>
        <Col md={6} className="mb-3">
          <TableHeader
            Search
            handleSearch={handleSearch}
            setSearchData={setSearchData}
            SearchReverseData={getProgramStudyId}
            AddTitle
            handleAddTitle={setToggle}
            titleAddTitle={"Add New Study Id"}
          />
          <StudyIdTableData
            studyListList={currentItemsDocumentCase}
            setViewDatasets={setViewDatasets}
            getDataSetsByProgramStudyId={getDataSetsByProgramStudyId}
            getProgramStudyId={getProgramStudyId}
            tableRef={tableRef}
            onMouseDown={onMouseDown}
          />
          <Pagination
            Result={currentItemsDocumentCase}
            itemsPerPage={itemsPerPageDocumentCase}
            handlePageClick={handlePageClickStudy}
            pageCount={pageCountDocumentCase}
          />
        </Col>
        {viewDatasets && (
          <Col md={6}>
            <DatasetsTableData
              viewDatasets={viewDatasets}
              datasetList={currentItems}
              studyId={studyId}
              getDataSetsByProgramStudyId={getDataSetsByProgramStudyId}
              onMouseDown={onMouseDown}
              tableRef={tableRef}
            />
            <Pagination
              Result={datasetList}
              itemsPerPage={itemsPerPage}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
            />
          </Col>
        )}
      </Row>
      <AddNewStudyIdModal
        Show={!toggle}
        getProgramStudyId={getProgramStudyId}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Study Id"}
        Submit={"Submit"}
      />
    </>
  );
};

export default Datasets;
