import React, { useState } from "react";
import AITableHeader from "../../AITableHeader";
import UseToggle from "../../../Hooks/useToggle";
import ProgramLibraryTable from "./ProgramLibraryTable";
import Pagination from "../../../Hooks/Pagination";
import AddFileFolderModal from "./AddFileFolderModal";
import { aiLibraryServices } from "../../../APIServices/AIAlpha/aiLibraryServices";
import Swal from "sweetalert2";

const ProgramLibrary = ({
  id,
  setId,
  breadcrumbData,
  setBreadcrumbData,
  setActiveBreadcrumb,
  activeBreadcrumb,
  handleChangeLibraryType,
  getAILibraryData,
  setLibrary,
  library,
  cleanToolsData,
  tableRef,
  onMouseDown,
}) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchData, setSearchData] = useState("");

  const itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = library?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(library?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % library?.length;
    setItemOffset(newOffset);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.path = "program";
    userData.searchData = searchValue || searchData;
    if (id) {
      userData.folderId = id;
    }
    let data = await aiLibraryServices.searchLibrary(userData);
    setLibrary(data?.data);
  };

  const addNewMultiLibraryFavorite = async (e, favorite) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${
        favorite ? "Add" : "Remove"
      } this library data in favorite?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, favorite it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.libraryIds = selectedIds;
        userData.favorite = favorite;
        let data = await aiLibraryServices.addMultiFavoriteAILibraryData(
          userData
        );
        if (data?.statusCode === 200) {
          if (id) {
            getAILibraryData("mainLibrary", id);
          } else {
            getAILibraryData("mainLibrary");
          }
        }
      }
    });
  };

  const deleteMultiAILibraryData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this library data?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.ids = selectedIds;
        let data = await aiLibraryServices.deleteMultiAILibraryData(userData);
        if (data?.statusCode === 200) {
          if (id) {
            getAILibraryData("mainLibrary", id);
          } else {
            getAILibraryData("mainLibrary");
          }
          setSelectedIds([]);
        }
      }
    });
  };

  const handleOpenAIResultPanel = (id) => {
    setId(id);
  };

  return (
    <>
      <AITableHeader
        cleanToolsData={cleanToolsData}
        Bookmark
        handleBookmark={(e) => handleChangeLibraryType(e, "favoriteLibrary")}
        Search={activeBreadcrumb?.type !== "file"}
        handleSearch={handleSearch}
        SearchReverseData={getAILibraryData}
        setSearchData={setSearchData}
        MultiDelete={
          selectedIds?.length > 0 && activeBreadcrumb?.type !== "file"
        }
        handleMultiDelete={deleteMultiAILibraryData}
        RemoveFavorite={
          selectedIds?.length > 0 && activeBreadcrumb?.type !== "file"
        }
        handleRemoveFavorite={(e) => addNewMultiLibraryFavorite(e, false)}
        AddFavorite={
          selectedIds?.length > 0 && activeBreadcrumb?.type !== "file"
        }
        handleFavorite={(e) => addNewMultiLibraryFavorite(e, true)}
        AddFolder={activeBreadcrumb?.type !== "file"}
        handleFolder={setToggle}
        AddFile={activeBreadcrumb?.type !== "file"}
        handleFile={setToggle1}
        setBreadcrumbData={setBreadcrumbData}
        breadcrumbData={breadcrumbData}
        activeBreadcrumb={activeBreadcrumb}
        setActiveBreadcrumb={setActiveBreadcrumb}
        setId={setId}
      />
      <div className="sidebar_Content libraryTables">
        <ProgramLibraryTable
          tableRef={tableRef}
          onMouseDown={onMouseDown}
          library={currentItems}
          getAILibraryData={getAILibraryData}
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          getAILibraryDataById={getAILibraryData}
          handleOpenAIResultPanel={handleOpenAIResultPanel}
          setBreadcrumbData={setBreadcrumbData}
          breadcrumbData={breadcrumbData}
          setActiveBreadcrumb={setActiveBreadcrumb}
          id={id}
          setId={setId}
        />
        <Pagination
          Result={library}
          itemsPerPage={itemsPerPage}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
        />
      </div>
      <AddFileFolderModal
        Show={!toggle}
        Hide={setToggle}
        Size={"sm"}
        type="folder"
        Title="Add New Folder"
        Submit={"Add Folder"}
        folderId={id}
        getAILibraryData={getAILibraryData}
      />
      <AddFileFolderModal
        Show={!toggle1}
        Hide={setToggle1}
        Size={"sm"}
        type="file"
        Title="Add New File"
        Submit={"Add File"}
        folderId={id}
        getAILibraryData={getAILibraryData}
      />
    </>
  );
};
export default ProgramLibrary;
