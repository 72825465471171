import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { aiLibraryServices } from "../../../APIServices/AIAlpha/aiLibraryServices";
import Swal from "sweetalert2";

const UpdateFileFolderModal = ({
  id,
  Show,
  Hide,
  Size,
  Title,
  Submit,
  type,
  getAILibraryData,
  libraryData,
}) => {
  let [name, setName] = useState("");

  const updateAILibraryData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Rename this ${type}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Rename it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = libraryData?._id;
        userData.name = name;
        let data = await aiLibraryServices.updateAILibraryData(userData);
        if (data?.statusCode === 200) {
          if (id) {
            getAILibraryData("mainLibrary", id);
          } else {
            getAILibraryData("mainLibrary");
          }
          cleanAndHideData();
        }
      }
    });
  };

  const cleanAndHideData = () => {
    setName("");
    Hide();
  };

  useEffect(() => {
    setName(libraryData?.name);
  }, [libraryData]);
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={cleanAndHideData} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="mb-2">
            <Form.Label className="text-capitalize">
              {type} Name <span>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder={`Enter ${
                type === "folder" ? "Folder" : "File"
              } Name`}
              autoFocus
            />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-end">
          <button
            className="themeButton"
            disabled={!name}
            onClick={updateAILibraryData}
          >
            {Submit}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateFileFolderModal;
