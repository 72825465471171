import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const BackupChartData = ({ graphData }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });
  let sum = graphData?.reduce((total, currentValue) => total + currentValue, 0);
  useEffect(() => {
    if (Array.isArray(graphData)) {
      setChartData({
        series: graphData,
        options: {
          chart: {
            type: "donut",
          },
          legend: {
            position: "bottom",
          },
          responsive: [
            {
              breakpoint: 500,
              options: {
                chart: {
                  width: 150,
                  height: 150,
                },
              },
            },
          ],
          labels: ["Total Deleted Items", "Total Restore Items"],
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val+"%";
            },
            style: {
              fontSize: "12px",
            },
          },
          colors: ["#ff000094", "#57ca4fd4"],
          plotOptions: {
            pie: {
              donut: {
                size: "40%",
              },
            },
          },
        },
      });
    }
  }, [graphData]);

  return (
    <div className="graph__title">
      <div id="chart">
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="donut"
          height="250"
        />
      </div>
    </div>
  );
};

export default BackupChartData;
