import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { creditsAIServices } from "../../../../APIServices/AIAlpha/creditsAIServices";
import Pagination from "../../../../Hooks/Pagination";
import ViewTransactionDetailsTable from "./ViewTransactionDetailsTable";

const ViewTransactionDetailsModal = ({ Show, Hide, Size, Title, type, id }) => {
  const [creditTransectionData, setCreditTransectionData] = useState([]);

  const getcreditTransectionData = async (type, id) => {
    let data = await creditsAIServices.getCreditTransectionByTime(type, id);
    setCreditTransectionData(data?.data);
  };

  const itemsPerPage = 15;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = creditTransectionData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(creditTransectionData?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % creditTransectionData?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    getcreditTransectionData(type, id);
  }, [type, id]);

  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div
            onClick={() => {
              Hide();
            }}
            className="close_modal"
            title="Close"
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <ViewTransactionDetailsTable creditTransectionData={currentItems} />
          <Pagination
            itemsPerPage={itemsPerPage}
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewTransactionDetailsModal;
