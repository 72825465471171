import React, { useEffect, useState } from "react";
import { Card, Col, Form, FormGroup, Modal, Row } from "react-bootstrap";
import BackupChartData from "./BackupChartData";
import { backupAIServices } from "../../../APIServices/AIAlpha/backupAIServices";

const BackupRequestModal = ({
  Show,
  Hide,
  Size,
  Title,
  calculateBackupDataPrice,
  fromDateTime,
  toDateTime,
  storeDateTime,
  setFromDateTime,
  setToDateTime,
  getAIRequestBackupData
}) => {
  const [pay, setPay] = useState(false)

  const cleanCalculateBackupData = () => {
    setFromDateTime("")
    setToDateTime("")
    setPay(false)
    Hide()
  }

const payCreditsForBackupData = async() => {
  let userData = {}
  userData.credits = (storeDateTime?.totalCredit)*2
  userData.fromDateTime = fromDateTime
  userData.toDateTime = toDateTime
  userData.restoreData = storeDateTime?.totalCalculateForRestoreData
  let data = await backupAIServices.payCreditsForBackupData(userData)
  if(data?.statusCode === 200){
    getAIRequestBackupData()
  }
}

  return (
    <>
      <Modal
        show={Show}
        onHide={cleanCalculateBackupData}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={cleanCalculateBackupData} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={5}>
              <div className="shadow-sm p-3 rounded" style={{ height: 250 }}>
                <Form.Label style={{ fontSize: 14 }}>
                  Filter Backup Data
                </Form.Label>
                <FormGroup className="mb-1">
                  <Form.Label className="m-0">From</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="select date & time"
                    value={fromDateTime}
                    onChange={(e) => {
                      setFromDateTime(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Label className="m-0">To</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="select date & time"
                    onChange={(e) => {
                      setToDateTime(e.target.value);
                    }}
                  />
                </FormGroup>
                <div className="text-end">
                  <button
                    disabled={!fromDateTime && !toDateTime}
                    className="themeButton"
                    onClick={() => { calculateBackupDataPrice(); setPay(true); }}
                  >
                    Calculate
                  </button>
                </div>
              </div>
              <Card className="shadow-sm border-0 mt-3">
                <Card.Body>
                  <div className="hstack">
                    <div
                      className="icon rounded-circle"
                      style={{ background: "#ff000094" }}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </div>
                    <div className="widget-data">
                      <Card.Title className="text-muted">
                        {storeDateTime?.totalDeletedData || 0}
                      </Card.Title>
                      <Card.Text>
                        <b>Total Deleted Items</b>
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card className="shadow-sm border-0 mt-2">
                <Card.Body>
                  <div className="hstack ">
                    <div
                      className="icon rounded-circle"
                      style={{ background: "#57ca4fd4" }}
                    >
                      <i className="fa-solid fa-trash-can-arrow-up"></i>
                    </div>
                    <div className="widget-data">
                      <Card.Title className="text-muted">
                        {storeDateTime?.totalCalculateForRestoreData || 0}
                      </Card.Title>
                      <Card.Text>
                        <b>Total Restored Items</b>
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={7}>
              <Card
                className="shadow-sm border-0 p-2 mb-3"
                style={{ height: 250 }}
              >
                <Card.Body>
                  <BackupChartData graphData={storeDateTime?.graphData} />
                </Card.Body>
              </Card>
              {pay &&
                <div className="p-3 m-1">
                  <FormGroup className=" mb-2 d-flex justify-content-between">
                    <Form.Label className="m-0">Total Charged Credit</Form.Label>
                    <p className="m-0" style={{ color: "#198754" }}>
                      <b><i className="fa-solid fa-coins"></i> {(storeDateTime?.totalCredit)*2 || 0}</b>
                    </p>
                  </FormGroup>
                  <hr />
                  <div className="text-end mt-2">
                    <button
                      onClick={payCreditsForBackupData} className="themeButton">
                      Pay Credtis
                    </button>
                  </div>
                </div>
              }
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BackupRequestModal;
