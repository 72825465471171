import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../APIHelpers/Responses";

export const creditsAIServices = {
  creditPrice,
  customerCreateCreditPayment,

  // Credit Transactions
  getCreditTransectionByDate,

  // Credit Transactions By Time
  getCreditTransectionByTime,

  // Credit Transaction For Week Graph
  getCreditTransectionForWeekGraph,

  // Credit Transaction For Year Graph
  getCreditTransectionForYearGraph,

  // Credit Transactions By Filter
  getCreditTransectionByFilter,

  // Export Credit Transactions
  exportCreditTransectionMultiFormat,

  // Total Remaining Credits
  getTotalRemainingCredit,

  // Payment Confirmations
  confirmPaymentStatus,

  // Billing Transactions
  getBillingTransections,

  // Export Billing Transections
  exportBillingTransectionsMultiFormat,

  // Billing Transections for Invoice,
  getBillingTransectionsforInvoice,

  // Billing Transection For Week Graph
  getBillingTransectionForWeekGraph,

  // Billing Transection For Year Graph
  getBillingTransectionForYearGraph
};

// Credit Transactions By Date
async function getCreditTransectionByDate(type) {
  const response = await fetch(
    `${config.API_URL}getCreditTransectionByDate/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Credit Transactions By Time
async function getCreditTransectionByTime(type, id) {
  const response = await fetch(
    `${config.API_URL}getCreditTransectionByTime/${type}/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Credit Transactions By Filter
async function getCreditTransectionByFilter(type, range) {
  const response = await fetch(
    `${config.API_URL}getCreditTransectionByFilter/${type}/${range}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Total Remaining Credits
async function getTotalRemainingCredit() {
  const response = await fetch(
    `${config.API_URL}getTotalRemainingCredit`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Credit Transaction For Week Graph
async function getCreditTransectionForWeekGraph() {
  const response = await fetch(
    `${config.API_URL}getCreditTransectionForWeekGraph`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Credit Transaction For Year Graph
async function getCreditTransectionForYearGraph(year) {
  const response = await fetch(
    `${config.API_URL}getCreditTransectionForYearGraph/${year}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Export Credit Transactions
async function exportCreditTransectionMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportCreditTransectionMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}

// Credit Price
async function creditPrice() {
  const response = await fetch(
    `${config.API_URL}creditPrice`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Customer Checkout
async function customerCreateCreditPayment(userData) {
  const response = await fetch(
    `${config.API_URL}customerCreateCreditPayment`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Customer Checkout
async function confirmPaymentStatus(userData) {
  const response = await fetch(
    `${config.API_URL}confirmPaymentStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Billing Transections for Invoice,
async function getBillingTransectionsforInvoice(transactionId) {
  const response = await fetch(
    `${config.API_URL}getBillingTransectionsforInvoice/${transactionId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Billing Transactions
async function getBillingTransections(type) {
  const response = await fetch(
    `${config.API_URL}getBillingTransections/${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Export Billing Transections
async function exportBillingTransectionsMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportBillingTransectionsMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}


// Billing Transection For Week Graph
async function getBillingTransectionForWeekGraph() {
  const response = await fetch(
    `${config.API_URL}getBillingTransectionForWeekGraph`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Billing Transaction For Year Graph
async function getBillingTransectionForYearGraph(year) {
  const response = await fetch(
    `${config.API_URL}getBillingTransectionForYearGraph/${year}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
