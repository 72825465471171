import React, { useEffect, useState } from "react";
import TableHeader from "../TableHeader";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";
import FailedTableStatus from "./FailedTableStatus";
import Pagination from "../../../Hooks/Pagination";
import UseToggle from "../../../Hooks/useToggle";
import ExportFailedHistoryModal from "./ExportFailedHistoryModal";

const FailedStatus = ({tableRef, onMouseDown}) => {
  const { toggle, setToggle } = UseToggle();
  const [failedLoginData, setFailedLoginData] = useState([]);
  const getFailedLoginRecord = async () => {
    let data = await loginActivitiesServices.getFailedLoginRecord();
    setFailedLoginData(data?.data);
  };
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = failedLoginData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(failedLoginData?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % failedLoginData?.length;
    setItemOffset(newOffset);
  };
  useEffect(() => {
    getFailedLoginRecord();
  }, []);
  return (
    <>
      <TableHeader
        Export
        handleExport={setToggle}
        titleExport={"Export Failed Login History"}
      />
      <div className="sidebar_Content">
        <FailedTableStatus failedLoginData={currentItems} onMouseDown={onMouseDown} tableRef={tableRef}/>
        <Pagination
          Result={failedLoginData}
          itemsPerPage={itemsPerPage}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
        />
      </div>
      <ExportFailedHistoryModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title="Export Failed Login History"
        Submit={"Export"}
      />
    </>
  );
};

export default FailedStatus;
