import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";

const TrashExportModal = ({ Show, Hide, Size, Title, Submit }) => {
  let [extension, setExtension] = useState("");

  const exportUserDataMultiFormat = async () => {
    let userData = {};
    userData.extension = extension;
    let data = await loginActivitiesServices.exportDeletedAlLibraryMultiFormat(
      userData
    );
    if (data?.data) {
      Hide();
    }
  };
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={Hide} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} md="12" className="mb-3">
            <Form.Label>
              Format <span>*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setExtension(e.target.value)}
              aria-label="Default select example"
            >
              <option>Select Format</option>
              <option value="csv">CSV</option>
              <option value="excel">Excel</option>
            </Form.Select>
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-end">
          <button
            className="themeButton"
            disabled={!extension}
            onClick={exportUserDataMultiFormat}
          >
            {Submit}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TrashExportModal;
