import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import AITableHeader from "../../AITableHeader";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import docco from 'react-syntax-highlighter/dist/esm/styles/hljs/docco';
import ProfilePic from "../../../image/blackprof.webp";
import AIUser from "../../../image/aiuser.png";

const ProgramAIOutputResult = ({
  setBreadcrumbData,
  breadcrumbData,
  activeBreadcrumb,
  setActiveBreadcrumb,
  setId,
  getAILibraryData,
  programList,
  cleanToolsData
}) => {

  const [copied, setCopied] = useState(false)
  const [indexData, setIndexData] = useState(null)
  const copyToClipboard = (text, index) => {
    setIndexData(index)
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <AITableHeader
        cleanToolsData={cleanToolsData}
        SearchReverseData={getAILibraryData}
        setBreadcrumbData={setBreadcrumbData}
        breadcrumbData={breadcrumbData}
        activeBreadcrumb={activeBreadcrumb}
        setActiveBreadcrumb={setActiveBreadcrumb}
        setId={setId}
      />
      {programList?.length > 0 ?
        <div className="autoProgramHeight">
          {programList?.map(({ language, query, result }, index) => (
            <div key={index} className="para_details resultImages mb-5">
              <div className="hstack gap-3 mt-2">
                <img src={ProfilePic} alt="" style={{ marginLeft: -30 }} />
                <p className="m-0" style={{ fontSize: 15 }}><b>You</b></p>
              </div>
              <SyntaxHighlighter style={docco}>
                {query?.trim()}
              </SyntaxHighlighter>
              <div className="hstack gap-3 mt-2">
                <img src={AIUser} alt="" style={{ marginLeft: -30 }} />
                <p className="m-0" style={{ fontSize: 15 }}><b>Alpha</b></p>
              </div>
              <div className="para_detail_description">
                <div className="hstack justify-content-between py-2 px-3 mt-1 rounded-top" style={{ background: "#000" }}>
                  <p className="m-0 text-uppercase">{language}</p>
                  <div style={{ cursor: "pointer" }} className="d-flex gap-1" onClick={() => copyToClipboard(result.trim(), index)}>
                    <i
                      className={`fa-regular ${copied ? "fa-circle-check" : "fa-copy"}`}
                      style={{ marginTop: 2 }}
                      title={copied && index === indexData ? "Copied" : "Copy to Clipboard"}
                    ></i> <p className="m-0">{copied && index === indexData ? "Copied" : "Copy Code"}</p>
                  </div>
                </div>
                <div className="m-0">
                  <SyntaxHighlighter language={language} style={darcula} className="black">
                    {result.trim()}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
          ))}
        </div>
        :
        <div className="autoProgramHeight d-grid">
          <div className="para_details NullImages text-center" style={{ placeSelf: "center" }}>
            <img src={AIUser} alt="" />
            <h4 className="mt-4"><b>How can i help with you?</b></h4>
          </div>
        </div>
      }
    </>
  );
};

export default ProgramAIOutputResult;
