import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../APIHelpers/Headers";
import { handleResponse, headersWithAuth, headersWithAuthWithoutBody } from "../../APIHelpers/Responses";

export const backupAIServices = {
  // calculate Backup Data
  calculateBackupDataPrice,
  payCreditsForBackupData,
  getAIRequestBackupData,
  exportAIRequestDatainMultiFormat
};

// Restore Library Data
async function calculateBackupDataPrice(userData) {
  const response = await fetch(
    `${config.API_URL}calculateBackupDataPrice`,
    headersWithAuth("PUT", userData , headers)
  );
  const data = await response.json();
  return data;
}

// Pay Credts For Backup Data
async function payCreditsForBackupData(userData) {
  const response = await fetch(
    `${config.API_URL}payCreditsForBackupData`,
    headersWithAuth("PUT", userData , headers)
  );
  const data = await response.json();
  return data;
}

// Get AI Request backup Data
async function getAIRequestBackupData() {
  const response = await fetch(
    `${config.API_URL}getAIRequestBackupData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Export Backup Request in Multiformat
async function exportAIRequestDatainMultiFormat(userData) {
  const response = await fetch(
    `${config.API_URL}exportAIRequestDatainMultiFormat`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  if (data.statusCode === 400) {
    await handleResponse(data);
    return data;
  } else {
    saveAs(url, data?.data);
    return data;
  }
}