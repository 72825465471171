import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const FailedTableStatus = ({ failedLoginData, onMouseDown, tableRef }) => {
  return (
    <>
      <div className="table-container overflow-auto tableLibrarySection">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="custom-table"
          hover
          bordered
        >
          <thead>
            <tr>
              <th style={{ width: 40 }} onMouseDown={(e) => onMouseDown(e, 0)}>
                Sr
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>IP Address</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Time Zone</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Location</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Email</th>
              <th style={{ width: 150 }} onMouseDown={(e) => onMouseDown(e, 5)}>
                Password
              </th>
              <th style={{ width: 200 }} onMouseDown={(e) => onMouseDown(e, 6)}>
                Try Time
              </th>
            </tr>
          </thead>
          <tbody>
            {failedLoginData?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.ipAddress}</td>
                <td>{item.timeZone}</td>
                <td>{item.location}</td>
                <td>{item.email}</td>
                <td>{item.password}</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">{item.createdAt}</Moment>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default FailedTableStatus;
