import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { creditsAIServices } from "../../../APIServices/AIAlpha/creditsAIServices";

const PaymentSuccess = () => {
  const [containerEl, setContainerEl] = useState(null);
  const confettiColors = [
    "#EF2964",
    "#00C09D",
    "#2D87B0",
    "#48485E",
    "#EFFF1D",
  ];
  const confettiAnimations = ["slow", "medium", "fast"];
  let confettiInterval;

  useEffect(() => {
    const el = document.querySelector(".js-container");
    setContainerEl(el);
    return () => {
      clearInterval(confettiInterval);
    };
  }, []);

  useEffect(() => {
    if (containerEl) {
      _setupElements();
      _renderConfetti();
    }
  }, [containerEl]);

  const _setupElements = () => {
    const container = containerEl;
    const elPosition = container.style.position;

    if (elPosition !== "relative" || elPosition !== "absolute") {
      container.style.position = "relative";
    }

    const confettiContainer = document.createElement("div");
    confettiContainer.classList.add("confetti-container");
    container.appendChild(confettiContainer);
  };

  const _renderConfetti = () => {
    confettiInterval = setInterval(() => {
      const confettiEl = document.createElement("div");
      const confettiSize = Math.floor(Math.random() * 10) + 10 + "px";
      const confettiBackground =
        confettiColors[Math.floor(Math.random() * confettiColors.length)];
      const confettiLeft =
        Math.floor(Math.random() * containerEl.offsetWidth) + "px";
      const confettiAnimation =
        confettiAnimations[
          Math.floor(Math.random() * confettiAnimations.length)
        ];

      confettiEl.classList.add(
        "confetti",
        "confetti--animation-" + confettiAnimation
      );
      confettiEl.style.left = confettiLeft;
      confettiEl.style.width = confettiSize;
      confettiEl.style.height = confettiSize;
      confettiEl.style.backgroundColor = confettiBackground;

      confettiEl.removeTimeout = setTimeout(() => {
        confettiEl.parentNode.removeChild(confettiEl);
      }, 3000);

      containerEl.querySelector(".confetti-container").appendChild(confettiEl);
    }, 25);
  };

  let { uniqueId } = useParams();

  const confirmPaymentStatus = async () => {
    let userData = {
      uniqueId: uniqueId,
    };
    await creditsAIServices.confirmPaymentStatus(userData);
  };

  useEffect(() => {
    confirmPaymentStatus();
  }, []);

  return (
    <>
      <div className="js-container"></div>
      <div className="thanksSection">
        <div className="p-0 login_page" md={5}>
          <Card className="shadow-sm border-0  w-25">
            <Card.Body>
              <section className="sign-up m-auto py-3">
                <div className="Success__taag mb-3">
                  <i
                    style={{ color: "green" }}
                    className="fa-solid fa-circle-check"
                    alt="Invitation Link Expired"
                  ></i>
                </div>
                <h5 className="text-center mb-3">
                  <b>Payment Successfull.</b>
                </h5>

                <Row>
                  <Form.Group as={Col} md="12" className="mb-3 text-center">
                    <Card.Text>
                      Thanks! Your Payment has been Successfully Completed.
                    </Card.Text>
                  </Form.Group>
                  <Form.Group as={Col} md="12" className="text-center">
                    <NavLink to={"/account/recharge"}>
                      <button className="mb-3 themeButton w-50">
                        Back to Billing
                      </button>
                    </NavLink>
                  </Form.Group>
                </Row>
              </section>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
