import React, { useEffect, useState } from "react";
import { Card, Form, Modal } from "react-bootstrap";
import { loadStripe } from '@stripe/stripe-js';
import { creditsAIServices } from "../../../APIServices/AIAlpha/creditsAIServices";

const AddCreditsModal = ({ Show, Hide, Size, Title, Submit }) => {
  const [credits, setCredits] = useState(1);
  const [price, setPrice] = useState(0)
  const [amount, setAmount] = useState(0)
  const [CGST, setCGST] = useState(0)
  const [SGST, setSGST] = useState(0)
  const [totalGST, setTotalGST] = useState(0)
  const [currency, setCurrency] = useState("$")

  const creditPrice = async () => {
    let data = await creditsAIServices.creditPrice()
    let result = data?.data
    setPrice(result?.price)
    setAmount(result?.price * credits)
    setCGST(result?.cgst / 100)
    setSGST(result?.sgst / 100)
    setTotalGST(((result?.cgst + result?.sgst) / 100).toFixed(2))
    setCurrency(result?.currency)
  }

  const handleNumChange = (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value) || value >= 1 || value <= 10) {
      setCredits(value);
      setAmount(value * price)
    }
  };

  const handleDecrement = () => {
    if (credits > 1) {
      setCredits(credits - 1);
      setAmount((credits - 1) * price)
    }
  };

  const handleIncrement = () => {
    if (credits < 10000000) {
      setCredits(credits + 1);
      setAmount((credits + 1) * price)
    }
  };
  
  const makeCreditsPayment = async () => {
    const stripe = await loadStripe("pk_test_51P9pwLSJpif8ij89TJfYP3uWjqApcLyiOMT46AVUtChgNQCbjPxePwsDlb0s2xrwU1tdclzCY9Cwb69vtFUiJueC00t12fuxoA")
    let userData = {}
    userData.item = {
      credits: credits,
      currency: currency
    }
    let data = await creditsAIServices.customerCreateCreditPayment(userData)
    let sessionId = data.sessionId

    stripe.redirectToCheckout({
      sessionId: sessionId
    })
  }

  useEffect(() => {
    creditPrice()
  }, []);

  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div
            onClick={() => {
              Hide();
            }}
            className="close_modal"
            title="Close"
          >
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="hstack justify-content-between">
            <p className="m-0" style={{ fontSize: 18 }}>
              <b>Credits</b>
            </p>
            <div>
              <button
                className="button-count no-active rounded-start"
                disabled={credits <= 1}
                onClick={handleDecrement}
              >
                -
              </button>
              <input
                type="text"
                className="number-credits border-start-0 border-end-0"
                value={credits}
                onChange={handleNumChange}
              />
              <button
                className="button-count rounded-end"
                onClick={handleIncrement}
              >
                +
              </button>
            </div>
          </div>
          <hr style={{ color: "var(--border-color)", opacity: 1 }} />
          <div className="hstack justify-content-between mb-2">
            <Card.Text className="m-0">
              <b>Sub Price : </b>
            </Card.Text>
            <Card.Text className="m-0 text-success">
              <b style={{ fontSize: 15 }}>{currency + amount}</b>
            </Card.Text>
          </div>
          <div className="hstack justify-content-between mb-2">
            <Card.Text className="m-0">
              <b>CGST ({CGST*100}%) : </b>
            </Card.Text>
            <Card.Text className="m-0 text-success">
              <b style={{ fontSize: 15 }}>{currency + (amount * CGST).toFixed(2)}</b>
            </Card.Text>
          </div>
          <div className="hstack justify-content-between mb-2">
            <Card.Text className="m-0">
              <b>SGST ({SGST*100}%) : </b>
            </Card.Text>
            <Card.Text className="m-0 text-success">
              <b style={{ fontSize: 15 }}>{currency + (amount * SGST).toFixed(2)}</b>
            </Card.Text>
          </div>
          <hr style={{ borderColor: "var(--border-color)", opacity: 1 }} />
          <div className="hstack justify-content-between mb-2">
            <Card.Text className="m-0">
              <b>Total Price : </b>
            </Card.Text>
            <Card.Text className="m-0 text-success">
              <b style={{ fontSize: 15 }}>{currency}{(amount + amount * Number(totalGST)).toFixed(2)}</b>
            </Card.Text>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="hstack justify-content-between mb-2 w-100">
            <div className="hstack">
              <Card.Text className="m-0">
                <b>{currency}per Credit : </b>
              </Card.Text>
              <Card.Text className="m-0 ms-2">{currency + price} / 1 credit</Card.Text>
            </div>
            <button
              disabled={!currency || !amount || !credits}
              onClick={() => makeCreditsPayment()}
              className="themeButton">{Submit}</button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddCreditsModal;
