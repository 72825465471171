import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";

const BackupTableData = ({ backupData, tableRef, onMouseDown }) => {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
        return "green";
      case "failed":
        return "red";
      default:
        return "inherit";
    }
  };
  return (
    <>
      <div className="table-container overflow-auto tableLibrarySection">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="custom-table"
          hover
          bordered
        >
          <thead>
            <tr>
              <th style={{ width: 40 }} onMouseDown={(e) => onMouseDown(e, 0)}>
                Sr
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>From</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>To</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Total Restore Item</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Used Credits</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Status</th>
              <th style={{ width: 150 }} onMouseDown={(e) => onMouseDown(e, 5)}>
                Request Date | Time
              </th>
              <th style={{ width: 150 }} onMouseDown={(e) => onMouseDown(e, 7)}>
                Restore Date | Time
              </th>
            </tr>
          </thead>
          <tbody>
            {backupData?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.fromDateTime}</td>
                <td>{item.toDateTime}</td>
                <td>{item.restoreData}</td>
                <td>{item.usedCredits}</td>
                <td>
                  <div className="text-capitalize" style={{ color: getStatusColor(item.status), fontWeight: 600 }}>
                    {item.status}
                  </div>
                </td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">
                    {item.createdAt}
                  </Moment>
                </td>
                <td>
                  {item.status === "completed" ?
                    <Moment format="DD MMM YYYY | HH:mm">
                      {item.updatedAt}
                    </Moment>
                    : "_"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default BackupTableData;
