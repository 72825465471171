import React from "react";
import { Table } from "react-bootstrap";
import UploadDatasetsModal from "./UploadDatasetsModal";
import UseToggle from "../../../../Hooks/useToggle";
import TableHeader from "../../TableHeader";
import ExportDatasetsModal from "./ExportDatasetsModal";

const DatasetsTableData = ({
  datasetList,
  viewDatasets,
  getDataSetsByProgramStudyId,
  studyId,
  tableRef,
  onMouseDown
}) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();

  return (
    <>
      {viewDatasets && (
        <>
          <div className="hstack justify-content-between sponsors_section_filter">
            <div className="hstack gap-2">
              <p className="m-0" style={{ fontWeight: "bold" }}>
                {viewDatasets}
              </p>
            </div>
            <TableHeader
              Upload
              handleUpload={setToggle}
              titleUpload="Upload Datasets"
              Export
              handleExport={setToggle1}
              titleExport="Export Datasets Table Data"
            />
          </div>
          <div
            className="table-container overflow-auto tableLibrarySection"
            style={{ maxHeight: "calc(100vh - 180px)" }}
          >
            <Table
              id="resizable-table"
              className="custom-table m-0"
              hover
              bordered
              ref={tableRef}
            >
              <thead>
                <tr>
                  <th style={{ width: 40 }} onMouseDown={(e) => onMouseDown(e, 0)}>Sr.</th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Datasets</th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Variables</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Labels</th>
                </tr>
              </thead>
              <tbody>
                {datasetList?.length > 0 ? (
                  datasetList?.map((item, index) => (
                    <tr className="relative">
                      <td>{index + 1}</td>
                      <td title={item.Datasets}>{item.Datasets}</td>
                      <td title={item.Variables}>{item.Variables}</td>
                      <td title={item.Labels}>{item.Labels}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="relative text-center">
                    <td colSpan={4}>
                      <div className="noRecordFound">Record not found</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <UploadDatasetsModal
              Show={!toggle}
              studyId={studyId}
              getDataSetsByProgramStudyId={getDataSetsByProgramStudyId}
              Hide={setToggle}
              Size={"md"}
              Title={"Upload Datasets"}
              Submit={"Upload"}
            />
            <ExportDatasetsModal
              Show={!toggle1}
              Hide={setToggle1}
              Size={"md"}
              Title="Export Datasets"
              Submit={"Export"}
              studyId={studyId}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DatasetsTableData;
