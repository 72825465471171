import config from "../config/config.json";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody
} from "../APIHelpers/Responses";

export const themeSettingsServices = {
  // Theme Settings
  updateStatusColorData,
  // getStatusColor,
  updateThemeColorData,
  getThemeColor,
  updateThemeFontData,
  getThemeFont,
};

// Update Status Color
async function updateStatusColorData(userData) {
  const response = await fetch(
    `${config.API_URL}updateStatusColorData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Status Color
// async function getStatusColor() {
//   const response = await fetch(
//     `${config.API_URL}getStatusColor`,
//     headersWithAuthWithoutBody("GET", headers)
//   );
//   const data = await response.json();
//   return data;
// }

// Update Theme Color
async function updateThemeColorData(userData) {
  const response = await fetch(
    `${config.API_URL}updateThemeColorData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Theme Color
async function getThemeColor() {
  const response = await fetch(
    `${config.API_URL}getThemeColor`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Theme Font
async function updateThemeFontData(userData) {
  const response = await fetch(
    `${config.API_URL}updateThemeFontData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Theme Font
async function getThemeFont() {
  const response = await fetch(
    `${config.API_URL}getThemeFont`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}