import React, { useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import Moment from "react-moment";

const TrashTableData = ({
  trashData,
  tableRef,
  onMouseDown,
  restoreAILibraryData,
  permanetDeletedAILibraryData,
  selectedIds,
  setSelectedIds,
}) => {
  const [action, setAction] = useState("");

  const handleSelectAll = () => {
    if (selectedIds?.length === trashData?.length) {
      setSelectedIds([]);
    } else {
      const allIds = trashData?.map((item) => item._id);
      setSelectedIds(allIds);
    }
  };

  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedIds.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };

  return (
    <>
      <div className="table-container overflow-auto tableLibrarySection">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="custom-table"
          hover
          bordered
        >
          <thead>
            <tr>
              <th style={{ width: 40 }} onMouseDown={(e) => onMouseDown(e, 0)}>
                <Form.Check
                  type="checkbox"
                  id="selectAll"
                  checked={
                    selectedIds?.length === 0
                      ? false
                      : selectedIds?.length === trashData?.length
                  }
                  onChange={handleSelectAll}
                />
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Path</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Category</th>
              <th style={{ width: 150 }} onMouseDown={(e) => onMouseDown(e, 4)}>
                Deleted Date | Time
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 5)} style={{ width: 65 }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {trashData?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Form.Check
                    type="checkbox"
                    id={`checkbox${index}`}
                    checked={selectedIds?.includes(item._id)}
                    onChange={() => handleCheckboxChange(item._id)}
                  />
                </td>
                <td>
                  <i
                    style={{ color: "var(--accent-200)" }}
                    className={`fa-solid ${
                      item.type === "file" ? "fa-file" : "fa-folder"
                    }`}
                  ></i>{" "}
                  {item.name}
                </td>
                <td className="text-capitalize">{item.path}</td>
                <td className="text-capitalize">{item.type}</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm">{item.deletedAt}</Moment>
                </td>
                <td>
                  <div className="text-center action__buttons">
                    {action === item._id ? (
                      <>
                        <div className="saperate_action_button">
                          <Button
                            onClick={(e) => restoreAILibraryData(e, item._id)}
                            title="Restore"
                            className="rounded-circle"
                          >
                            <i className="fa-solid fa-rotate-left"></i>
                          </Button>
                          <Button
                            onClick={(e) =>
                              permanetDeletedAILibraryData(e, item._id)
                            }
                            title="Delete"
                            className="rounded-circle close_circle"
                          >
                            <i className="fa-solid fa-trash"></i>
                          </Button>
                        </div>
                        <Button
                          onClick={() => setAction("")}
                          title="Close Action"
                          className="rounded-circle close_circle"
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </Button>
                      </>
                    ) : (
                      <Button
                        onClick={() => setAction(item._id)}
                        title="Open Action"
                        className="rounded-circle"
                      >
                        <i className="fa-solid fa-bars"></i>
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default TrashTableData;
