import React, { useState } from "react";
import { Breadcrumb, Form } from "react-bootstrap";

const AITableHeader = ({
  Search,
  handleSearch,
  seachData,
  setSearchData,
  SearchReverseData,
  MultiDelete,
  handleMultiDelete,
  AddFavorite,
  handleFavorite,
  AddFolder,
  handleFolder,
  AddFile,
  handleFile,
  setBreadcrumbData,
  breadcrumbData,
  activeBreadcrumb,
  setActiveBreadcrumb,
  PDFDownload,
  handlePDFDownload,
  DocDownload,
  handleDocsDownload,
  setId,
  Bookmark,
  handleBookmark,
  Library,
  handleLibrary,
  RemoveFavorite,
  handleRemoveFavorite,
  cleanToolsData
}) => {
  const [filter, setFilter] = useState(false);
  const [libraryType, setLibraryType] = useState("");

  const handleBredcrumbChange = (libraryType) => {
    localStorage.removeItem("activeDocumentBreadcrumbData");
    localStorage.removeItem("breadcrumbDocumentData");
    localStorage.removeItem("activeProgramBreadcrumbData");
    localStorage.removeItem("breadcrumbProgramData");
    setBreadcrumbData([]);
    setActiveBreadcrumb("");
    SearchReverseData(libraryType);
    setLibraryType(libraryType)
    setId("")
    cleanToolsData()
  };

  const goToPreviousBreadcrumb = (e, id) => {
    const indexToRemoveFrom = breadcrumbData?.findIndex(
      (item) => item._id === id
    );

    if (indexToRemoveFrom !== -1) {
      breadcrumbData?.splice(indexToRemoveFrom + 1);
    }
    const lastIndex = breadcrumbData?.length - 1;
    let activeBreadcrumb = breadcrumbData[lastIndex];
    setActiveBreadcrumb(activeBreadcrumb);
    SearchReverseData(libraryType, activeBreadcrumb?._id);
    setId(activeBreadcrumb?._id);
    if (activeBreadcrumb?.path === "document") {
      localStorage.setItem(
        "activeDocumentBreadcrumbData",
        JSON.stringify(activeBreadcrumb)
      );
      localStorage.setItem(
        "breadcrumbDocumentData",
        JSON.stringify(breadcrumbData)
      );
    } else {
      localStorage.setItem(
        "activeProgramBreadcrumbData",
        JSON.stringify(activeBreadcrumb)
      );
      localStorage.setItem(
        "breadcrumbProgramData",
        JSON.stringify(breadcrumbData)
      );
    }
    cleanToolsData()
  };

  return (
    <>
      <div className="content_cpm">
        <div className="hstack justify-content-between">
          <div style={{ overflowX: "auto" }} className="blank">
            <Breadcrumb style={{ display: "inline-flex" }}>
              <Breadcrumb.Item
                onClick={()=>handleBredcrumbChange(JSON.parse(localStorage.getItem("libriryType")) || "mainLibrary")}
                title="Back To Home"
                style={{ position: "sticky", left: 0, background: "var(--bg-100)" }}
                className="pe-2"
              >
                <b>
                  <i className="fa-solid fa-house me-1"></i> Home
                </b>
              </Breadcrumb.Item>
              {breadcrumbData?.map((item, index) =>
                activeBreadcrumb?._id === item?._id ? (
                  <Breadcrumb.Item
                    style={{ pointerEvents: "none" }}
                    key={index}
                  >
                    <i
                      className={`fa-solid me-1 ${activeBreadcrumb?.type === "folder"
                          ? "fa-folder-open"
                          : "fa-file"
                        }`}
                    ></i>
                    {item?.name}
                  </Breadcrumb.Item>
                ) : (
                  <Breadcrumb.Item
                    key={index}
                    onClick={(e) => goToPreviousBreadcrumb(e, item._id)}
                  >
                    <b>
                      <i
                        className={`fa-solid me-1 ${item?.type === "folder" ? "fa-folder" : "fa-file"
                          }`}
                      ></i>
                      {item?.name}
                    </b>
                  </Breadcrumb.Item>
                )
              )}
            </Breadcrumb>
          </div>

          <div className="button_group hstack gap-3">
            {Bookmark && (
              <button
                onClick={() => { handleBookmark(); handleBredcrumbChange("favoriteLibrary")}}
                title="View Bookmarks"
                className="ms-2"
              >
                <i className="fa-solid fa-bookmark"></i>
              </button>
            )}
            {Library && (
              <button
                onClick={() => { handleLibrary(); handleBredcrumbChange("mainLibrary")}}
                title={"Main Library"}
                className="ms-2"
              >
                <i className="fa-solid fa-list"></i>
              </button>
            )}
            {Search && (
              <>
                {filter ? (
                  <div className="hstack">
                    <button
                      onClick={() => {
                        setFilter(false);
                        SearchReverseData(libraryType, activeBreadcrumb?._id);
                      }}
                      title="Cancel"
                      className="close_circle"
                      style={{ borderRadius: "3px 0px 0px 3px" }}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                    <div className="hstack">
                      <Form.Control
                        required
                        value={seachData}
                        onChange={(e) => {
                          handleSearch(e.target.value);
                          setSearchData(e.target.value);
                        }}
                        className="border-left border-right"
                        type="search"
                        placeholder="Search"
                      />
                      <button
                        title="Search"
                        onClick={() => handleSearch()}
                        style={{ borderRadius: "0px 3px 3px 0px", width: 40 }}
                      >
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    </div>
                  </div>
                ) : (
                  <button onClick={() => setFilter(true)} title="Filter">
                    <i className="fa-solid fa-filter"></i>
                  </button>
                )}
              </>
            )}
            {MultiDelete && (
              <button
                onClick={handleMultiDelete}
                title="Delete"
                className="close_circle"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            )}
            {RemoveFavorite && (
              <button onClick={handleRemoveFavorite} title="Remove Favorite" className="close_circle">
                <i className="fa-regular fa-star"></i>
              </button>
            )}
            {AddFavorite && (
              <button onClick={handleFavorite} title="Add Favorite">
                <i className="fa-solid fa-star"></i>
              </button>
            )}
            {AddFolder && (
              <button onClick={handleFolder} title="Add New Folder">
                <i className="fa-solid fa-folder-plus"></i>
              </button>
            )}
            {AddFile && (
              <button onClick={handleFile} title="Add New File">
                <i className="fa-solid fa-file-circle-plus"></i>
              </button>
            )}
            {PDFDownload && (
              <button onClick={handlePDFDownload} title="Download PDF">
                <i className="fa-solid fa-file-pdf"></i>
              </button>
            )}
            {DocDownload && (
              <button onClick={handleDocsDownload} title="Download Doc">
                <i className="fa-solid fa-file-word"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AITableHeader;
